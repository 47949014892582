.activity_page_main_wrapper {
    .activity_page_banner {
        background-image: url("../../assets/banner.png");
    }
}

.gallery_page_container {
    .gallery_page_main_wrapper {
        .gallery_page_banner {
            background-image: url("../../assets/banner.png");
        }
    }
}

.news_page_banner {
    background-image: url("../../assets/banner.png");
}

.success_stories_banner {
    background-image: url("../../assets/banner.png");
}

.village_page_container {
    .village_page_main_wrapper {
        .village_page_banner {
            background-image: url("../../assets/banner.png");
        }
    }
}

