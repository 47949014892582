//all other images that are at side
.slick-slide {
    opacity: 0.5;
    transform: scale(0.8);
    transition: 0.3s all ease;
    // transition-delay: 0.3s;
}

//image which is at center
.slick-current {
    transition: 0.3s all ease;
    transform: scale(1);
    opacity: 1;
    transition-delay: 0.44s;
    
}

//slick carousel arrows
.slick-prev:before, .slick-next:before {
    color: rgba(rgb(66, 66, 66), 1) !important;
    font-size: 50px !important;
    font-family: "";
    background-repeat: no-repeat;
}


.slick_carousel {
    margin-bottom: 16rem;

    //background-color of testimonial section
    background-color: #F8F8F8;


    // "Testimonial" word styling 
    h1 {
        font-size: 32px;
        font-family: 'Ubuntu';
        color: rgba(rgb(51, 51, 51), 1);
        text-align: center;
        padding-top: 3rem;
    }

    //line "Testimonial" word
    hr {
        width: 22%;
        margin: 2rem auto;
        background-color: rgba(white, 0.8);
    }

    //slick carousel image
    img {
        margin-top: 2rem;
        border-radius: 50%;
        transition: all 0.3s;
        max-width: 200px;
        &:hover {
            cursor: pointer;
        //     transform: scale(1.1);
        }
      }

    //carousel content
    .slick_carousel_content {
        padding-bottom: 1.5rem;

        //person name
        h4 {
            font-size: 24px;
            font-family: $font-10;
        }

        //person designation
        h5 {
            color: rgba(black, 0.6);
            font-size: 17px;
            margin-bottom: 2.5rem;
        }

        //person message
        h3 {
            max-width: 80%;
            margin: 1.4rem auto;
            letter-spacing: 1.2px;
            color: rgba(rgb(36, 36, 36), 0.8);
            font-size: 18px;
        }
    }
}

//media queries 

@media (max-width:550px) {

    .slick_carousel {
        //carousel content
        img {
            max-width: 150px;
        }
    }

    //slick carousel arrows
    .slick-prev:before, .slick-next:before {
        color: rgba(rgb(66, 66, 66), 1) !important;
        font-size: 50px !important;
        font-family: "";
        background-repeat: no-repeat;
    }
    .slick-next {
        right: 1% !important;
    }
    .slick-prev {
        left: -5% !important;
    }
}

@media (max-width:320px) {

    //slick carousel arrows
    .slick-prev:before, .slick-next:before {
        color: rgba(rgb(66, 66, 66), 1) !important;
        font-size: 44px !important;
        font-family: "";
        background-repeat: no-repeat;
    }
    .slick-next {
        right: 3% !important;
    }
    .slick-prev {
        left: -5% !important;
    }

    .slick_carousel {
        //carousel content
        img {
            max-width: 120px;
        }
    .slick_carousel_content {
        padding-bottom: 1.5rem;

        //person name
        h4 {
            font-size: 19px;
            font-family: $font-10;
        }

        //person designation
        h5 {
            color: rgba(black, 0.6);
            font-size: 16px;
            margin-bottom: 1.8rem;
            font-family: $font-2;
        }

        //person message
        h3 {
            color: rgba(black, 0.8);
            font-size: 15px;
        }
    }
    }
    
}