.dropdown {
  font-size: 1.8rem;
  padding-top: 0.5rem;
  &:hover {
    // .fa-caret-down {
    //   transform: rotate(180deg);
    //   transition: all 0.5s ease;
    // }
    cursor: pointer;
    .dropdown-content {
      // display: block;
    }
    
  
  }
  .title {
    color: black !important;
    font-family: Open Sans;
    font-size:2.5rem;
    font-weight: 100;
    padding: 0.5rem 2rem;
    margin: 0;
    opacity: 0.9;
    &:hover{
      opacity: 1;
    }
  }

  .slash::after{
    content:'/';
    font-family: Open Sans;
    font-size:3rem;
    font-weight: 100;
    margin-left: 4rem;

  }
  

  ul {
    list-style-type: none;
  }

}

.dropdown-content {
      animation-name: dropdown;
      animation-duration: 0.5s;
      animation-direction: alternate;
      transition: all 0.3s ease-in-out; 
  // border: 1px solid rgba(#000, 0.1);
  // display: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: absolute;
  right: -20px;
  // left: -1.3rem;
  background-color: $color-1;
  font-weight: 500;
  padding: 0.7rem 0 0.7rem ;
  min-width: 17rem;
  // animation-name: dropdownOut;
  // animation-duration: 0.5s;
  // animation-direction: alternate;
  // transition: all 0.3s ease-in-out; 
  text-align: left ;
  z-index: 1;
  // border: 2px solid #888;
  border-radius: 0.4rem;
  // animation-name: dropdown1;
  // animation-duration: 0.5s;
  // animation-direction: alternate; 
  transition: all 0.3s ease-in-out;
  &:hover {
      cursor: pointer;
  }
  li {
    font-family: $font-6;
    padding: 0.5rem;
    &:hover {
      background:rgba(108, 201, 165, 0.3);
      span {
        color:$color-hover-dropdown;
      }
    }
  }
  span {
    color: $color-2;
    font-size: 1.5rem;
    padding-left: 0.5rem;
  }

}

.fa-caret-down {
  display: none !important;
  animation-name: arrowDown;
  animation-duration: 0.5s;
  animation-direction: alternate;
  transition: all 0.3s ease-in-out; 
    
}

.fa-caret-up {
  display: none !important;
  animation-name: arrowUp;
  animation-duration: 0.5s;
  animation-direction: alternate;
  transition: all 0.3s ease-in-out; 
    
}


@keyframes dropdown {
  from {
      opacity: 0;
      transform: translateY(-14px);
  }
  to {
      opacity: 1;
      transform: translateY(0px);
  }
}



@keyframes arrowDown {
  from {
      transform: rotate(180deg);
  }
  to {
      transform: rotate(0deg);
  }
}
@keyframes arrowUp {
  from {
      transform: rotate(-180deg);
  }
  to {
      transform: rotate(0deg);
  }
}
// @keyframes dropdownOut {
//   from {
//       opacity: 1;
//       transform: translateY(0px);
//   }
//   to {
//       opacity: 0;
//       transform: translateY(-14px);
//   }
// }


.dropdown-arrow{
  color: $color-1;
  font-size: 1.5rem;
  // background-color: blue;
  margin: 0;
}

@media (min-width:121px) and (max-width:375px) {
  .dropdown {
    font-size: 1.1rem;
  }
}

@media (min-width:375.98px) and (max-width:550px) {
  .dropdown {
    font-size: 1.4rem;
  } 
}

.dropdowns-container{
  float: right;
  position: absolute;
  right: 0;
  top: 0.3rem;
}