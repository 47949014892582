.testimonial2-section {
    display: flex;
    padding: 2rem;
    padding-bottom: 10rem;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    // background-color: yellowgreen;
    .image-container {
        position: relative;
        display: flex;
        margin-top: auto;
        margin-bottom: auto;
        flex-shrink: 0;
        width: 35rem;
        height: 35rem;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        overflow: hidden;
        .graphic-circle {
            height: 100%;
            width: 100%;
            z-index: -1;
            position: absolute;
            background-image: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
            border-radius: 50%;
        }
        .testimonial-image {
            width: 100%;
            height: 100%;
            transform: translateX(2.5rem);
        }
    }
    .testimonial-text-container {
        flex-grow: 1;
        flex-basis: 75%;
        flex-shrink: 1;
        margin-top: 8rem;
        margin-left: 3rem;
        h2 {
            position: relative;
            z-index: 20;
            font-family: $font-7;
            font-size: 4.5rem;
            // text-align: right;
        }

        .testimonial-rsk-paragraph {
            position: relative;
            font-family: $font-7;
            font-size: 3rem;
            line-height: 4rem;
            font-weight: 300;
        }

        .ranjit-singh-more-button {
            float: right;
            margin-right: 4rem;
            background-image: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
            border: none;
            outline: none;
            font-family: $font-7;
            font-size: 2rem;
            padding: 0.2rem 1.5rem;
            transition: all 0.4s;
        }
    }

    .ranjit-singh-more-button:hover {
        transform: scale(1.1);
    }
}

@media (max-width: 1640px) {
    .testimonial-text-container {
        flex-basis: 80%;
        h2 {
            margin-top: 0rem;
            font-size: 1rem;
            text-align: left;
        }
    }
}
// @media (max-width: 999px) {
//     .testimonial2-section {
//         padding-top: 3rem;
//         padding-bottom: 30rem;
//         h2 {
//             font-size: 3.6rem;
//             position: absolute;
//             margin-top: -10rem;
//         }
//     }
// }
// @media (max-width: 769px) {
//     .testimonial2-section {
//         padding-top: 3rem;
//         padding-bottom: 35rem;
//         h2 {
//             font-size: 3rem;
//             position: absolute;
//             margin-top: 4rem;
//             width: 100%;
//             text-align: center;
//         }

//         .image-container {
//             max-width: 50%;
//             .graphic-circle {
//                 height: 100%;
//                 width: 100%;
//                 position: absolute;
//                 margin-left: 50%;
//                 // top: -10%;
//                 // display: none;
//             }

//             .testimonial-image {
//                 margin-left: 50%;
//                 // margin-top: -4rem;
//                 // transform: translateY(-15%);
//             }
//         }
//     }
// }
// @media (max-width: 559px) {
//     .testimonial2-section {
//         // padding-bottom: 25rem;
//         h2 {
//             margin-top: 10%;
//             font-size: 2.5rem;
//             // border: 8px solid red;
//             width: 80%;
//             // margin: 0 auto;
//             margin-right: 13%;
//             // margin-top: 2rem;
//             // transform: translateY(-2rem);
//         }

//         .image-container {
//             .testimonial-image {
//                 // width: 120%;
//                 // margin-bottom: 0;
//                 // border: 1rem solid yellow;
//                 // transform: translateY(15%);
//             }
//         }
//     }
// }
// @media (max-width: 510px) {
//     .testimonial2-section {
//         // padding-bottom: 20rem;
//         h2 {
//             font-size: 2.5rem;
//             // border: 8px solid red;
//             width: 100%;
//             margin: 0 auto;
//             margin-right: 5%;
//             margin-top: 5%;
//             // transform: translateY(-70%);
//         }

//         .image-container {
//             .testimonial-image {
//                 // width: 120%;
//                 // margin-bottom: 0;
//                 // border: 1rem solid yellow;
//                 // transform: translateY(15%);
//             }
//         }
//     }
// }
// @media (max-width: 450px) {
//     .testimonial2-section {
//         // padding-bottom: 20rem;
//         h2 {
//             font-size: 2.5rem;
//             // border: 8px solid red;
//             // width: 100%;
//             // margin: 0 auto;
//             // margin-right: 5%;
//             margin-top: 0%;
//             // transform: translateY(-70%);
//         }

//         .image-container {
//             // background-color: red;
//             width: 100%;
//             .testimonial-image {
//                 // width: 120%;
//                 // margin-bottom: 0;
//                 // border: 1rem solid yellow;
//                 // transform: translateY(15%);
//             }
//             .graphic-circle {
//                 // width: 120%;
//             }
//         }
//     }
// }

// @media (max-width: 410px) {
//     .testimonial2-section {
//         // padding-bottom: 20rem;
//         h2 {
//             font-size: 2rem;
//             // border: 8px solid red;
//             // width: 100%;
//             // margin: 0 auto;
//             // margin-right: 5%;
//             margin-top: 2rem;
//             // transform: translateY(-70%);
//         }

//         .image-container {
//             // background-color: red;
//             width: 100%;
//             .testimonial-image {
//                 width: 120%;
//                 // margin-bottom: 0;
//                 // border: 1rem solid yellow;
//                 // transform: translateY(15%);
//             }
//             .graphic-circle {
//                 width: 120%;
//             }
//         }
//     }
// }
