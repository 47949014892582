.wrapper {
    z-index: -1;
    background-color: #fff;
}
.blob {
    z-index: -2;
    width: 0;
    height: 0;
    position: absolute;
    transition: .5s ease;
    transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
    top: 50%;
    left: 55%;
}
.homePage_activity_cards_background_color {
    // background-color: rgba(rgb(200, 207, 248), 0.1);
}

.column-content {
    
    cursor: pointer;
    &:hover {
        .content {
            opacity: 0.8;
        }
        .overlay {
            top: -1rem;
            left: -1rem;
            width: 100%;
            opacity: 1;
            height: 100%;
            border-radius: 0.7rem;
            margin: 1rem;
          }
          .blob {
            width: 120%;
            height: 500px;
            // z-index: 1;
          }

          .iitk-logo-card{
              transform: scale(0.4);
            //   z-index: 4;
          }

          
    }
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 100%;
    opacity: 1;
    // margin: 1rem;
    overflow: hidden;
    width: 20%;
    height: 30%;
    // z-index: 9;
    transition: .5s ease;
    border-top-left-radius: 0.7rem;
    border-bottom-right-radius: 0.7rem;
  }



.gradient {
    border: 5px solid transparent;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #00C853, #B2FF59);
}
.homePage_activity_cards_background_color {
    // background-image: url('../../assets/wm.jpg');
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    // background: linear-gradient(90deg, #2494B8 0%, #0782B7 100%);

}
.card-container{
    // background-color: blue;
    
    width: 100%;
    padding: 1rem;
    // margin-top: 5rem;
    .activity_title {
        margin-top: 1.6rem;
        font-size: 33px;
        font-family: $font-10;
        font-weight: 500;
        // color: rgba(black, 0.9);
        color: rgba(rgb(36, 35, 35), 0.9);
    }
    hr {
        width: 22%;
        margin: 2rem auto;
        background-color: rgba(white, 0.8);
    }
}

.card-image_{
    width: 100%;
    max-height: 25rem;
    border-radius: 0.7rem;
    overflow: hidden;
}

.card-title_{
    position: absolute;
    width: 100%;
    bottom: -0.55rem;
    text-align: center;
    background-color: $color-card-title-background;
    color: white;
    padding: 1rem 0;
    font-family: $font-10;
    font-size: 2.2rem;
    font-weight: 500;
    border-bottom-left-radius: 0.7rem;
    border-bottom-right-radius: 0.7rem;
    
}

.iitk-logo-card{
    transition: all .5s;
    transform: scale(0.5);
}

.activity-card{
    margin: 1rem 1rem;
    position: relative;
    z-index: 1;
    // border: 1rem solid black;
}