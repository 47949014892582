.simple-page-container {
    min-height: 70vh;
    margin-top: 11rem;

    h3 {
        font-size: 33px;
        margin-bottom: 1.6rem;
    }
    .simple-page-content {
        font-size: 16px;
        padding-bottom: 6rem;
        max-width: 65%;
    }
}

@media (max-width:600px) {
    .simple-page-container {
        min-height: 70vh;
        margin-top: 11rem;
    
        h3 {
            font-size: 33px;
            margin-bottom: 1.6rem;
        }
        .simple-page-content {
            font-size: 16px;
            padding-bottom: 6rem;
            max-width: 95%;
        }
    }
}