.donate_join_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0;
    height: 100vh;
    right: 10px;
    top: 40vh;
    z-index: 1;
    position: fixed;
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(black, 1);
        font-size: 1.2rem;
        padding: 2rem;
        margin: 1.2rem;
        width: 7rem;
        height: 7rem;
        border: 2px solid rgba(black, 1);
        font-family: $font-6;
        font-weight: 700;
        border-radius: 50%;
        transition: all 0.3s ease;
        &:hover {
            cursor: pointer;
            transform: scale(1.08);
        }
    }
}

@media (min-width:121px) and (max-width:350px) {
    .homepage_banner_container { 
        .homepage_banner_heading {
            margin-top: 1.2rem;
            font-weight: 600;
            font-size: 23px;
        }
        .homepage_banner_subheading {
            font-size: 23px;
        }
    }
}
@media (min-width:350px) and (max-width:440px) {
    .homepage_banner_container { 
        .homepage_banner_heading {
            font-weight: 600;
            font-size: 30px;
        }
        .homepage_banner_subheading {
            font-size: 23px;
        }
    }
}
@media (min-width:440px) and (max-width:550px) {
    .donate_join_btn {
        top: 10vh;
        span {
            padding: 2rem;
            width: 5.6rem;
            height: 5.6rem;
            font-size: 10px;
        }
    }
}
@media (max-width:517px) {
    .donate_join_btn {
        display: none;
    }
}
@media (min-width:550.98px) and (max-width:767.98px) {
    .donate_join_btn {
        top: 14vh;
        span {
            padding: 2.7rem;
        }
    }
}

@media (min-width:768px) and (max-width:991.98px) {
    .donate_join_btn {
        top: 23vh;
        span {
            padding: 3rem;
        }
    }
    
}

@media (min-width:992px) and (max-width:1024px) {
    .donate_join_btn {
        top: 17vh;
    }
}

@media (min-width:1024.98px) and (max-width:1202px) {
    .donate_join_btn {
        top: 24vh;
    }
}