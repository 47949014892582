.homepage_banner_container {
    .banner-lower-container-for-mobile {
        position: absolute; 
        left: 0%;
        bottom: 3%;
        display: flex;
        z-index: 2;
        .image-banner-lower-container {
            margin-top: 1.5rem;
            .banner-lower-image {
                margin-top: -10%;
                width: 60%;
            }

            .banner-lower-image-caption {
                padding-left: 20%;
                text-align: center;
                
            }
        }

        .homepage-slogan-container {
            h1 {
                background-color: rgba(black, 0.6);
                border-radius: 1rem;
                color: rgb(255, 255, 255);
                padding: 1rem;
                font-size: 12px;
                max-width: 80%;
                font-family: $font-6;
                font-style: italic;
                letter-spacing: 0.1rem;
            }

            .down-pointing-arrow-to-image {

            }
        }
    }


}


@media (min-width:768.98px) {
    .banner-upper-container {
        .banner-upper-container-latest-for-mobile {
            display: none;
        }
    }

    .homepage_banner_container {
        .banner-lower-container-for-mobile {
            display: none;
        }
    }
    
}

@media (max-width:769px) {
    .homepage_banner_container {
        .banner-lower-container {
            display: none;
        }
        .banner-upper-container-latest {
            display: none;
        }
    }


    
}