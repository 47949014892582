// Basic color scheme 
$color-1: #ffffff;
$color-2: rgba(0,0,0,0.9);
$color-3: #ffc914;
$color-4: #67AAF9;
$color-5: #629460;
$color-6: #F97068;
$color-bg: #cadd94;
$color-7: #fefefe;
$color-card-title-background: rgba(#000708,0.5);
$color-font-heading: #f2f2f2;
$color-hover-dropdown:rgb(32, 32, 32);
$navbar-color: rgba(15, 15, 15, 1); 
$about_page_color:linear-gradient(90deg, #354DA2 0.01%, #053A61 100%);
//Fonts
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;600;900&display=swap');@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&family=Roboto+Mono&family=Trispace&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800;400;200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,800;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Itim&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,400;0,700;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;1,300;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;1,300;1,700&family=Roboto:ital,wght@0,300;0,400;1,300;1,400&display=swap');
$font-1: Lora;
$font-2: Source Sans Pro;
$font-3:Noto Sans;
$font-4:Roboto Mono;
$font-5:Trispace;
$font-6:Open Sans;
$font-7:Caveat;
$font-8:Josefin Slab;
$font-9: Titillium Web;
$font-10:Ubuntu;
$font-11:Poppins;
$font-12: Roboto Condensed;
$font-13: Roboto;
