.homePage_carousel_wrapper {
  position: relative;
  // border-radius: 1.2rem;
  overflow: hidden;
  // border: 1rem solid black;
  // height: 50vh;
  .homePage_carousel_heading {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.4rem;
    margin-bottom: 0;
    background-color: rgba(black, 0.8);
    color: rgba(white, 0.9);
    border-bottom-right-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem;
    
  }
    img {
    border-bottom-right-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem;
    object-fit: cover;
    }
}
// Carousel-root-1 carousel-container
.CarouselItem,.Carousel-root-1  {
  
  border-radius: 1.2rem;
}
.carousel-container{
  // height: 40vh;
    // height: auto;
    // max-height: 50rem;
    width: 60%;
    margin: 0 auto 16rem auto;
    
}
.MuiPaper-root {
    box-shadow: none !important;
}
// .MuiIconButton-root {
//     // margin-top: 50% !important;
// }
// Project MuiPaper-elevation10 MuiPaper-rounded
.carousel-heading{
    text-align: center;
    font-size: 3rem;
    font-family: $font-1;
    margin-bottom: 3rem;
}
.Carousel-button-7 {
    outline: none !important;
    // opacity: 0.8 !important;
}

@media (min-width:121px) and (max-width:260px) {
  .carousel-container{
    width: 90%;  
  }
  .homePage_carousel_wrapper {
      .homePage_carousel_heading {
        font-size: 6px;
        padding: 0.7rem;
        border-bottom-right-radius: 0.4rem;
        border-bottom-left-radius: 0.4rem;
      }
  }
  .CarouselItem,.Carousel-root-1  {
    border-radius: 0.4rem;
  }
}

@media (min-width:260px) and (max-width:375px) {
  .carousel-container{
    width: 90%;  
  }
  .homePage_carousel_wrapper {
      .homePage_carousel_heading {
        font-size: 12px;
        padding: 0.7rem;
        border-bottom-right-radius: 0.7rem;
        border-bottom-left-radius: 0.7rem;
      }
  }
  .CarouselItem,.Carousel-root-1  {
    border-radius: 0.7rem;
  }
}
@media (min-width:375px) and (max-width:550px) {
  .carousel-container{
    width: 90%;  
  }
  .homePage_carousel_wrapper {
      .homePage_carousel_heading {
        font-size: 14px;
        padding: 1rem;
        border-bottom-right-radius: 0.7rem;
        border-bottom-left-radius: 0.7rem;
      }
  }
  .CarouselItem,.Carousel-root-1  {
    border-radius: 0.7rem;
  }
}

@media (min-width:550px) and (max-width:767.98px) {
  .carousel-container{
    width: 90%;  
  }
  .homePage_carousel_wrapper {
      .homePage_carousel_heading {
        font-size: 14px;
        padding: 1.1rem;
        border-bottom-right-radius: 0.7rem;
        border-bottom-left-radius: 0.7rem;
      }
  }
  .CarouselItem,.Carousel-root-1  {
    border-radius: 0.7rem;
  }
}

@media (min-width:767.98px) and (max-width:991.98px) {
  .carousel-container{
    width: 85%;  
  }
  .homePage_carousel_wrapper {
      .homePage_carousel_heading {
        font-size: 14px;
        padding: 1.1rem;
        border-bottom-right-radius: 0.9rem;
        border-bottom-left-radius: 0.9rem;
      }
  }
  .CarouselItem,.Carousel-root-1  {
    border-radius: 0.9rem;
  }
}

@media (min-width:991.98px) and (max-width:1202px) {
  .carousel-container{
    width: 70%;  
  }
  .homePage_carousel_wrapper {
      .homePage_carousel_heading {
        font-size: 14px;
        padding: 1.4rem;
        border-bottom-right-radius: 0.9rem;
        border-bottom-left-radius: 0.9rem;
      }
  }
  .CarouselItem,.Carousel-root-1  {
    border-radius: 0.9rem;
  }
}

@media (min-width:1202px) {
  .carousel-container{
    width: 70%; 
    margin-top: 16rem; 
  }
}