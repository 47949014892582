.each_Item_activity_page_banner {

    z-index: -1;
    position: relative;
    // height: 34vh;
    img {
        // position: absolute;
        // left: 0;
        // right: 0;
        width: 100%;
        height: 60vh;
        object-fit: cover;
        object-position: center
    }
    .banner_headings {
        position: absolute;
        // bottom: 16%;
        z-index: 10001;
        top: 26%;
        left: 0%;
        padding: 4rem;
        // background-color: rgba(rgb(170, 33, 136), 0.6);
        h1 {
            // margin-top: 2rem;
            font-size: 30px;
        }
        h2 {
            font-size: 16px;
            color: rgba(rgb(43, 43, 43), 0.8);
        }
    }

    
}



.activity_page_content_container {
    margin-bottom: 6rem;
    margin-top: -7rem;
    background-color: rgba(white, 1);
    h4 {
        padding: 2rem;
        font-size: 26px;
    }
    .activity_page_content {
        font-size: 15px;
        padding: 2.2rem;
        width: 87%;
        margin: 0 auto;
        transition: all 0.3s;
        background-color: rgba(white, 1);
        z-index: 1;
        &::before, &::after {
            content:'';
            box-shadow:0 20px 15px #777;
            transform:rotate(-5deg);
            position:absolute;
        bottom:20px;
        left:10px;
        top:80%;
        z-index:-2;
        }
    }
    
}
.activity_page_main_wrapper {
    background-color: rgba(grey, 0.2);

    .activity_page_banner {
        height: 35vh;
        // width: 100%;
        margin-bottom: 6rem;
        // background-image: url("../../assets/back_1.svg");
        background-repeat: no-repeat;
        background-size: cover; 
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        h1 {
            position: absolute;
            top: 50%;
            color: rgba(white, 0.9);
            font-size: 4rem;
            font-family: $font-9;
        }
    }
}
.activity_Page_card_template {
    margin: 1.3rem;
    padding: 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.5rem;
    background-color: rgba(white, 0.9);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: all 0.3s;
    overflow: hidden;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
        transform: scale(1.02);
    }
    .activity_page_card_image {
        // margin: 0.3rem;
        transition: all 0.5s;
        // overflow:hidden;
        border-radius: 0.3rem;
    }
    .activity_page_card_logo {
        width: 4rem;
        
    }
    h1 {
        img {
            padding: 0.4rem;
            margin: 0.6rem;
        }
        font-size: 17px;
        display:flex;
        align-items: center;
        margin-bottom: 0;

    }
}

@media (max-width:375px) {
    .activity_page_content {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        }
}

@media (max-width:320px) {
    .activity_page_content_container {
        margin-bottom: 6rem;
        margin-top: -7rem;
        background-color: rgba(white, 1);
        h4 {
            padding: 2rem;
            font-size: 22px;
        }
        .activity_page_content {
            width: 100%;
            margin: 0 auto;
            padding: 0;
            }
        }
        .each_Item_activity_page_banner {
            img {
                width: 100%;
                height: 42vh;

            }
        }
}