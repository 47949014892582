.homepage_banner_container {
  position: relative;
  height: 100vh;
  .banner-upper-container {
    position: absolute;
    // border: 1rem solid black;
    max-height: 33vh;
    left: 0%;
    top: 15%;
    display: flex;
    z-index: 2;
    flex-grow: 1;
    width: 100%;
    // background-color: red;
    justify-content: space-between;
    flex-direction: row;
    padding-left: 8rem;

    &-heading {
      // background-color: blue;
      display: flex;
      flex-direction: column;
    }
    &-latest {
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 4;
      max-width: 50rem;
      min-width: 20rem;
      max-height: fit-content;
      border-radius: 2rem;
      padding: 1rem 4rem;
      margin-right: 4rem;
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      .recent-news-heading {
        color: white;
        font-family: Open Sans;
        font-size: 20px;
        font-weight: 700;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 2px solid rgba(255, 255, 255, 0.5);
      }
      .recent-news-element {
        color: white;
        font-family: Open Sans;
        font-size: 16px;
        font-weight: 400;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        z-index: 1;
      }
    }
  }

  .banner-lower-container {
    position: absolute;
    left: 0%;
    bottom: 5%;
    display: flex;
    z-index: 2;
    flex-grow: 1;
    width: 100%;
    // background-color: red;
    align-items: center;
    justify-content: space-around;
    padding-left: 8rem;

    .homepage-slogan-container {
      max-width: 120rem;
    }
    .homepage-slogan {
      background-color: rgba(0, 0, 0, 0.5);
      max-width: 90rem;
      // background-color: pink;
      transform: translate(3rem, 10rem);
      font-family: Open Sans;
      font-style: italic;
      font-weight: 400;
      font-size: 25px;
      color: white;
      align-items: center;
      text-align: center;
      display: flex;
      align-self: center;
      justify-content: center;
      padding-top: 2rem;
      padding-bottom: 2rem;
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 2rem;
      border-top-right-radius: 0;
      flex-grow: 1;
    }

    .homepage-slogan:after {
      content: "";
      height: 2rem;
      position: absolute;
      right: -5rem;
      top: 0;
      width: 5rem;
      background-color: rgba(0, 0, 0, 0.5);
      clip-path: polygon(0 0, 100% 0, 0 100%);
    }
    .image-banner-lower-container {
      min-width: 35rem;
      max-width: 45rem;
      flex-grow: 1;
      // background-color: red;
    }
    .banner-lower-image {
      width: 80%;
    }
    .banner-lower-image-label {
      font-family: Open Sans;
      font-size: 2.7rem;
      font-weight: 400;
      font-style: italic;
      text-align: center;
      width: 100%;
      color: white;
    }
  }
  .homepage_banner_heading {
    font-family: $font-6;
    font-weight: 700;
    opacity: 1;
    z-index: 1;
    font-size: 90px;
    transform: translateY(2rem);
  }
  .homepage_banner_heading1 {
    font-family: $font-6;
    font-weight: 700;
    opacity: 1;
    z-index: 1;
    margin-bottom: 2rem;
    font-size: 27px;
    transform: translateY(2rem);
  }
  .homepage_banner_subheading {
    font-size: 80px;
    font-weight: 700;
    font-family: $font-7;
    // color: white !important;
    // color: #ffc400;
  }

  video {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
}
.homePage_figure_container {
  // margin-bottom: 4rem;
  .homePage_figure_image {
    width: 70%;
  }
}
@media (min-width: 121px) and (max-width: 350px) {
  .homepage_banner_container {
    height: 92.6vh;
    .homepage_banner_heading {
      margin-top: -3rem;
      font-weight: 600;
      font-size: 45px;
      margin-left: -6rem;
      max-width: 20%;
    }
    .homepage_banner_heading1 {
      display: none;
    }
    .homepage_logos {
      display: none;
    }
    .homepage_banner_subheading {
      font-size: 26px;
      margin-top: 1.3rem;
      margin-left: -6rem;
    }
    video {
      height: 100% !important;
    }
  }
}

@media (max-width: 320px) {
  .homepage_banner_container {
    .homepage_banner_heading {
      margin-top: -3rem;
      font-size: 45px;
    }
    .homepage_banner_heading1 {
      display: none;
    }

    .homepage_logos {
      display: none;
    }
  }
}

@media (min-width: 350px) and (max-width: 440px) {
  .homepage_banner_container {
    .homepage_banner_heading {
      margin-top: -4rem;
      font-weight: 600;
      font-size: 45px;
      margin-left: -6rem;
      max-width: 20%;
    }
    .homepage_banner_subheading {
      font-size: 26px;
      margin-top: 1.3rem;
      margin-left: -6rem;
    }
    .homepage_banner_heading1 {
      display: none;
    }

    .homepage_logos {
      display: none;
    }
  }
}
@media (min-width: 440px) and (max-width: 550px) {
  .homepage_banner_container {
    .homepage_banner_heading {
      margin-top: -2rem;
      font-weight: 600;
      font-size: 35px;
      margin-left: -6rem;
      max-width: 20%;
    }
    .homepage_banner_subheading {
      font-size: 26px;
      margin-top: 1.3rem;
      margin-left: -6rem;
    }
    .homepage_banner_heading1 {
      display: none;
    }

    .homepage_logos {
      display: none;
    }
  }
}

@media (min-width: 550.98px) and (max-width: 767.98px) {
  .homepage_banner_container {
    .homepage_banner_heading {
      font-weight: 600;
      font-size: 30px;
    }
    .homepage_banner_subheading {
      font-size: 23px;
    }
    .homepage_banner_heading1 {
      display: none;
    }

    .homepage_logos {
      display: none;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .homepage_banner_container {
    .homepage_banner_heading {
      font-weight: 600;
      font-size: 35px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1202px) {
  .homepage_banner_container {
    .homepage_banner_heading {
      font-weight: 600;
      font-size: 30px;
    }
  }
  .homepage_banner_subheading {
    font-size: 35px;
  }
}

@media (min-width: 1202.98px) and (max-width: 1302px) {
  .homepage_banner_container {
    .homepage_banner_heading {
      font-weight: 600;
      font-size: 33px;
      top: 33%;
    }
    .homepage_banner_subheading {
      font-size: 43px;
    }
  }
  .homePage_figure_container {
    margin: 20rem auto;
    .homePage_figure_image {
      width: 53%;
    }
  }
}

@media (min-width: 1302.98px) {
  .homepage_banner_container {
    .homepage_banner_heading {
      font-weight: 600;
      font-size: 38px;
      top: 33%;
    }
    .homepage_banner_subheading {
      font-size: 43px;
    }
  }
  .homePage_figure_container {
    margin: 20rem auto;
    .homePage_figure_image {
      width: 53%;
    }
  }
}
