.container_testimonial_page {
    h1 {
        position: absolute;
        top: 15%;
        left: 10%;
        font-size: 50px;
        font-weight: 800;
        font-family: $font-6;
    }
    position: relative;
    height: 100vh;
    .container_testimonials_image {
        position: absolute;
        bottom: 0;
        margin-bottom: -30px;
        .img_container_testimonial {
            position: relative;
            .blob_testimonial_image {
                position: absolute;
                top: 0;
                // right: 0.001%;
                // left: 1%;
                z-index: -1;
                width: 130%;
                // height: 90%;
                // margin-top: 28%;
            }
        }
    }
    .container_testimonial_contents {
        position: absolute;
        top: 20%;
        left: 53%;
        // height: 35vh;
        width: 40%;
        margin: 0 auto;
        .content_name_and_message {
            padding: 2rem;
            border-radius: 2rem;
            // height: 38vh;
            transition: all 0.5s;
        }
        h2 {
            font-size: 64px;
            margin: 1.5rem;
            font-family: $font-6;
            font-weight: 700;
        }
        h3 {
            font-family: $font-8;
            font-size: 40px;
            margin: 1.5rem;
            font-weight: 700;
            // margin: 0.5rem;
        }
        h4 {
            margin: 1.5rem;
            font-size: 24px;
            font-family: $font-2;
            font-weight: 600;
        }
        .content_to_image_line {
            position: absolute;
            top: 55%;
            right: 100%;
        }
        .line_display_none {
            display: none !important;
        }
        .content_to_image_line_ {
            display: none;
        }
        .line_display_block {
            display: block !important;
        }
    }
    .testimonial_image {
        transition: all 0.5 ease;
        &:hover{
            cursor: pointer;
            // transform: transformY(-10px) !important;
            ;
        }
    }
}



.carousel {
    margin: 0 auto;
    width: 60% !important;
    color: rgba(black, 1);
    border-radius: 0.1rem;

    //testimonial carousel indicators
      .carousel-indicators {
          bottom: -1rem;
        li {
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background-color: #343232;
        }
        .active {
          background-color: #004168 !important;
          color: #004168 !important;
        }
      }

      //testimonial carousel arrows
      .carousel-control-next {
        position: absolute;
        // right: -8%;
      }
      .carousel-control-prev {
        position: absolute;
        // left: -8%;
      }
    }

    .carousel_container_ {
    // background-color: #2380a8;
      margin: 16rem auto;

      //testimonial section main heading
      h1 {
        text-align: center;
        font-size: 33px;
        font-family: Ubuntu;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.7);
      }
      hr {
        width: 18%;
        margin: 2rem auto;
      }
    }

    //testimonial carousel left and right arrows
    .carousel-control-next-icon {
      background-image: url("../../assets/carousel_arrow_next.svg") !important;
    }
    .carousel-control-prev-icon {
      background-image: url("../../assets/carousel_arrow_prev.svg") !important;
    }
  
  .carousel_item_wrapper_ {
      
      h4 {
        font-size: 2.7rem;
        font-family: $font-9;
        font-weight: 600;
      }
      h5 {
        font-size: 1.5rem;
        font-family: $font-1;
        font-weight: 400;
      }
      h6 {
        text-align: center;
        font-size: 1.9rem;
        font-family: 'Architects Daughter';
        max-width: 80%;
        margin: 0 auto;
        letter-spacing: 0.09rem;
      }
      hr {
          width: 50%;
          margin: 2rem auto;
      }
  }