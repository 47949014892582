//all notifications container
.all_notifications_wrapper {
    margin-top: 6rem;
    min-height: 100vh;
    // background-color: rgba(grey, 0.1);
}
.all_notifications_container {

    margin: 0 auto !important;
    width: 60% !important;
    .notifications_table {
        
        margin: 2.6rem auto;
        border-radius: 1.2rem !important;
        thead {
            th {
                font-size: 14px;
                font-family: $font-10;
            }
        }
        .notification_title {
            font-size: 15px;
            font-family: $font-10;
            font-weight: 600;
        }
        .created_at {
            font-size: 14px;
        }
        .open_this_notification {
            a {
                color: #31829B;
            }
            font-size: 13px;
        }
    }
}
.navbar_notification_bell {
    padding: 0.6rem 1.8rem 0 0;
    color: rgba(white, 0.9);
    &:hover {
        cursor: pointer;
        color: rgba(white, 1);
    }
}

.each_notification {
    margin: 8rem auto;
    .back_button {
        transition: all 0.3s;
        background-color: rgba(rgb(180, 180, 180), 0.3);
        border: 1px solid rgba(rgb(180, 180, 180), 0.36);
        padding: 0.5rem;
        border-radius: 4px;
        font-size: 13px;
        a {
            color: rgba(black, 0.7);
        }

        &:hover {
            background-color: rgba(rgb(148, 148, 148), 0.3);
        }
    }
    .to_notifications {
        a {
            color: #31829B;
            &:hover {
                color: #225768;
            }
        }
    }
    h1 {
        font-size: 30px;
    }
    h5 {
        color: grey;
        margin-bottom: 3rem;
    }
    table {
        color: rgba(black, 0.8);
        font-weight: 500;

    }
    .notification_body {
        font-size: 15px;
        padding: 0.2rem;
        
    }
}
.notification_box {
    animation: notifyBox 0.3s alternate;
    position: absolute;
    right: 5%;
    margin-top: 1rem;
    height: 23.5rem;
    overflow: auto;
    background-color: rgba(white, 1);
    border-radius: 0.3rem;
    // z-index: 0;
    transition: all 0.3s ease;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    .notification_caret {
        position: absolute;
        right: 9.5%;
        top: -5%;
        // color: rgba(white, 0.3);
        z-index: 1009 !important;
    }
    ul {
        transition: all 0.3s ease;
        margin: 0;
        padding: 0.9rem 0 0.9rem 0;
        list-style: none;
        .notification_item {
            font-size: 14px;
            padding: 0 0.7rem 0 0.7rem;
            font-weight: 600;
            &:hover {
                cursor: pointer;
                background-color: rgba(#f5f5f5, 1);
            }
            
            
        }
        
    }
}

//notification box animation
@keyframes notifyBox {
    from {
        opacity: 0;
        margin-top: 2rem;
    }
    to {
        opacity: 1;
        margin-top: 1rem;
    }
}


//read all link at the bottom of notification section
.fa-external-link {
    transition: all 0.4s;
    padding-left: 0.2rem;
}
.read_all_notifications_external_link {
    font-size: 14px;
    &:hover {
        .fa-external-link {
            padding-left: 0.5rem;
        }
    }
}


@media (max-width:767px) {
    .all_notifications_container {
        margin: 0 auto !important;
        width: 94% !important;
    }
}