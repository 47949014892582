._card_button {
    transition: all 0.3s;
    color:#2a85ad;
    padding: 1rem;
    font-weight: 600;
    &:hover {
        color: #14465c;
        .fa-external-link {
            padding-left:0.9rem !important;
        }
    }
}
.partner_page_main_heading {
    font-size: 36px;
    font-family: 'Ubuntu';
    font-weight: 500;
    color: rgba(rgb(48, 48, 48), 0.9);
}
._card-wrapper {
    margin: 1.2rem;
}
.partner_cards_container {
    min-height: 100vh;
    padding-top: 6rem;
    margin-bottom: 16rem;
}
._card_body {
    // background-color: rgba(white, 0.8);
}
._card {
    height: 27rem;
    transition: all 0.3s;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
        background-color: rgb(149, 228, 238);
    }
    h5 {
        font-family: $font-6;
        font-weight: 600;
        color: rgba(black, 0.7);
        font-size: 16px;
        margin-top: 1.3rem;
        // padding-left: 1rem;

    }
    img {
        width: 100%;
        height: 20rem;
        object-fit: cover;
        
        
    }
    

}
.fa-external-link {
    animation-name:externalLink;
    animation-duration:0.5s;
    animation-direction: alternate;
    transition: all 0.3s ease-in-out; 
    vertical-align: middle;
}
.partner-new-page-wrapper {
    min-height: 70vh;
    margin-top: 1.6rem;
    padding-bottom: 10rem;
}

.partner-page-logo {
    margin-top: 12rem;
}

.partner-new-page-body {
    margin-top: 10rem;
    .partner-new-page-content {
        font-size: 15px;
    }
}

@keyframes externalLink {
    from {
        padding-left:0rem !important;
    }
    to {
        padding-left:0.9rem !important;
    }
}

@media (min-width:767.98px) and (max-width:991.98px) {
    .partner_page_each_card {
        min-width: 33.33%;
    }
    ._card {
        height: 29rem;
    }
}

@media (max-width:767.98px) {
    .partner-new-page-img-wrapper {
        display: flex;
        justify-content: center;
    }
}