.news_Page_wrapper {
    background-color: rgba(grey, 0.1);
}
.news_page_banner {
    height: 35vh;
    // width: 100%;
    // margin-bottom: 6rem;
    // background-image: url("../../assets/back_1.svg");
    background-repeat: no-repeat;
    background-size: cover; 
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        position: absolute;
        top: 50%;
        color: rgba(white, 0.9);
        font-size: 4rem;
        font-family: $font-9;
    }
}

.container- {
    width: 60%;
    margin: 0 auto;
    
}
@media (max-width:768px) {
    .container- {
        width: 95%;
        margin: 0 auto;
    }   
}

.newsPage_container {
    padding-top: 6rem;
    padding-bottom: 8rem;
}
.newsPage_wrapper {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    position: relative;
    height: 30rem;
    margin: 1.4rem;
    // width: 30rem;
    overflow: hidden;
    border-radius: 0.7rem;
    background-color: rgba(black, 0.9);
    .news_card_img {
        border-radius: 0.7rem;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.5s;
        &:hover {
            transform: scale(1.05);
            transform-origin: 50% 50%;
        }
    }
    .newsPage_card_content {
        position: absolute;
        opacity: 0.75;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 1rem;
        background-color: black;
        color: rgba(white, 0.9);
    }

    .newsPage_card_date {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.9;
        padding: 1rem;
        color: rgba(white, 0.9);
        background-color: rgba(black, 1);
        border-bottom-right-radius: 0.6rem;

    }
}