/*SECTION GRID*/
.img-stack {
    display: grid;
    position: relative;
    grid-template-columns: repeat(12, 1fr);
}
/*PERSON STANDING IMAGE WHEN ALIGN IS LEFT*/
.img-stack-1 {
    grid-column: 1 / span 10;
    grid-row: 1;
    padding-top: 10%;
    z-index: 2;
    img {
        margin-left: -8%;
    } 
}

/*PERSON STANDING IMAGE WHEN ALIGN IS RIGHT*/
.img-stack-1__align_right {
    grid-column: 7 / span 12;
    grid-row: 1;
    padding-top: 20%;
    margin-left: 8%;
    z-index: 2;
}

/*MIDDLE IMAGE WHEN ALIGN IS LEFT*/
.img-stack-2 {
    // grid-column: 4 / span -1;
    margin-top: 2%;
    grid-row: 1;
    z-index: -1;
}

/*MIDDLE IMAGE WHEN ALIGN IS RIGHT*/
.img-stack-2__align_right {
    margin-top: 2%;
    grid-column: 1 / 5;
    margin-left: 4%;
    grid-row: 1;
    z-index: -1;
}

/*MAIN HEADING WHEN ALIGN IS LEFT*/
.heading-img-main {
    margin-left: 20%;
    text-align: right;
    padding-top: 4%;
    font-family: $font-6;
    h1 {
        font-size: 92px;
        font-weight: 900;
    }
    h3 {
        font-weight: 800;
        font-family: $font-2;
    }
}

/*MAIN HEADING WHEN ALIGN IS RIGHT*/
.heading-img-main__align_right {
    width: 70%;
    text-align: left;
    padding-top: 4%;
    font-family: $font-6;
    h1 {
        font-size: 92px;
        font-weight: 900;
    }
    .subheading__align_right {
        position: relative;
        font-size: 30px;
        grid-row: 1;
        grid-column: 1 / span 5;
        text-align: left;
        margin-right: 40%;
        padding-top: 2%;
        font-weight: 800;
        font-family: $font-2;
    }
}
/*WE BELIEVE HEADING*/
.heading-img-bottom {
    // margin-left: 10%;
    text-align: center;
    font-weight: 800;
    font-family: $font-6;
}
.heading_img_bottom__align_right {
    text-align: left;
    font-weight: 800;
    font-family: $font-6;
}

/*GROUP CONSISTING OF MAIN HEADING, SUBHEADING, MIDDLE IMAGE AND DESCRIPTION*/
.group-1 {
    position: absolute;
    .heading-img-main {
        position: relative;
    }
    .img-stack-2 {
        // position: relative;
    }
    .describe-content {
        position: relative;
        font-family: $font-3;
        margin-left: 43%;
        text-align: left;
        grid-row: 1;
        grid-column: 8 /-1;
        .description {
            font-size: 14px;
        }
    }
}
/*DESCRIPTION WHEN ALIGN IS RIGHT*/
.describe-content__align_right {
    position: relative;
    font-size: 16px;
    font-family: $font-3;
    // margin-left: 43%;
    text-align: left;
    grid-row: 1;
    grid-column: 1 / 5;
    .description {
        text-align: left;
    }
}

.primary_area_imp_links_for_mobile_view {
    display: none;
}

@media (min-width: 120px) and (max-width: 500px) {
    .description {
        font-size:14px;
    }
    .img-stack-1 {
        opacity:0;
    }

    .img-stack-1__align_right {
       display: none;
    }
    .img-stack-2 {
        img {
            margin-left: 18%;
            width: 70%;
        }
    }
    .img-stack-2__align_right {
        margin-top: 2%;
        margin-left: 4%;
        img {
            width:70%;
        }
    }
    .heading-img-main {
        text-align: right;
        margin-right:3%;
        margin-left:10%;
        h1 {
            font-size: 33px;
            font-weight: 900;
            
        }
        h3 {
            font-size: 25px;
            margin-left: 27%;
            text-align: right;
            padding-top: 2%;
        }
    }
    .heading-img-main__align_right {
        width: 70%;
        text-align: left;
        padding-top: 4%;
        margin-left:3%;
        font-family: $font-6;
        h1 {
            font-size: 25px;
            font-weight: 900;
        }
        .subheading__align_right {
            position: relative;
            font-size: 17px;
            margin-right: 40%;
            padding-top: 2%;
            font-weight: 800;
            font-family: $font-2;
        }
    }
    .heading-img-bottom { /*WE BELIEVE*/
        margin-left:-37%;
        margin-top: 1%;
        font-size: 20px;
    }
    .description {
        margin-left:-40%;

    }
    .heading_img_bottom__align_right {/*WE BELIEVE WITH ALIGN RIGHT*/
        margin-top: 1%;
        margin-left:4%;
        font-size:20px;
    }
    .describe-content__align_right {
        margin-left: 29%;
        margin-right:10%;
    }
    .container-11 {
        width: 98%;
        margin: 0 auto;
        height: 90vh;
    }
    .img-stack-1 {
        display: none;
    }
    .primary_area_imp_links_for_mobile_view {
        padding-left: 0;
        .links_heading_for_mobile_view {
            padding: 0.7rem 50% 0.7rem 0.7rem !important;
            
        }
    }

}

@media (min-width: 500px) and (max-width: 830px) {
    .description {
        font-size:14px;
        margin-right: 30px;
    }
    
    .img-stack-1 {
        padding-top: 10%;
        z-index: 2;
        img {
            margin-left: 6%;
            width:45%;
        }
    }

    .img-stack-1__align_right {
        padding-top: 10%;
        margin-left: -4%;
        img {
            width:80%;
        }
    }
    .img-stack-2 {
        img {
            margin-left: 18%;
            width: 70%;
        }
    }
    .img-stack-2__align_right {
        margin-top: 2%;
        margin-left: 4%;
        img {
            width:70%;
        }
    }
    .heading-img-main {
        text-align: right;
        margin-right:10%;
        h1 {
            font-size: 40px;
            font-weight: 900;
            
        }
        h3 {
            font-size: 25px;
            margin-left: 27%;
            text-align: right;
            padding-top: 2%;
        }
    }
    .heading-img-main__align_right {
        width: 70%;
        text-align: left;
        padding-top: 4%;
        font-family: $font-6;
        h1 {
            font-size: 40px;
            font-weight: 900;
        }
        .subheading__align_right {
            position: relative;
            font-size: 25px;
            grid-row: 1;
            grid-column: 1 / span 5;
            text-align: left;
            margin-right: 40%;
            padding-top: 2%;
            font-weight: 800;
            font-family: $font-2;
        }
    }

    .heading-img-bottom { /*WE BELIEVE*/
        margin-left: 10%;
        margin-top: 1%;
        font-size: 30px;
    }
    .heading_img_bottom__align_right {/*WE BELIEVE WITH ALIGN RIGHT*/
        margin-top: 1%;
        font-size:30px;
    }
    .describe-content__align_right {
        margin-right: 40%;
        
    }
}


@media (min-width:121px) and (max-width:375px) {
    .container-11 {
        width: 98%;
        margin: 0 auto;
        height: 85vh;
    }
    .img-stack-1, .im {
        display: none;
    }
    .img-stack-2 {
        .im2 {
            margin-left: 6%;
        }
    }
    // .gap_between_each_primary_section {
    //     height: 0rem !important;
    // }
    .heading-img-main {
        text-align: left;
        margin-top: 2.5rem;
        margin-left: 6%;
        
        h1 {
            font-size: 23px;
        }
        h3 {
            text-align: left;
            margin: 0;
            font-size: 17px;
        }
    }
    .heading-img-main__align_right {
        .subheading__align_right {
            margin-right: 0;
            font-size: 15px;
        }
    }
    .describe-content {
        margin-left: 33% !important;
    }
    .heading-img-bottom {
        // text-align: left;
        margin-left: -50%;
    }
    .primary_area_imp_links_for_mobile_view {
        padding-left: 0;
        .links_heading_for_mobile_view {
            padding: 0.7rem 50% 0.7rem 0.7rem !important;
            
        }
    }
}
@media (min-width:375.98px) and (max-width:450px) {
    .container-11 {
        width: 90%;
        margin: 0 auto;
        height: 50vh;
    }
    .subheading__align_right {
        margin-right: 0 !important;
    }
}
@media (min-width:450.98px) and (max-width:551px) {
    .container-11 {
        width: 90%;
        margin: 0 auto;
        height: 72vh;
    }
    .img-stack-1__align_right {
        .im1 {
            display: none;
        }
    }
    // .describe-content {
    //     margin-left: 17% !important;
    // }
    .heading-img-bottom {
        margin-left: -28%;
    }
    .subheading__align_right {
        margin-right: 0 !important;
    }
    .heading-img-main__align_right {
        h1 {
            font-size: 33px;
            // margin-top: 5rem;
        }
    }
    
}
@media (max-width:500.98px) {
    .primary_area_imp_links_for_mobile_view {
        display: block;
        list-style: none;
        margin-left: -40%;
        .links_heading_for_mobile_view {
            font-size: 16px;
            padding: 0.7rem 50% 0.7rem 0.7rem;
            
        }
        li {
            font-size: 15px;
            margin-top: 0.8rem;
            color: #2a85ad;
            transition: all 0.3s;
            &:hover {
                color: #14465c;
                
                cursor: pointer;
            }
            
        }
    }
}
@media (min-width:500px) and (max-width:550px) {
    .primary_area_imp_links_for_mobile_view {
        display: block;
        list-style: none;
        // margin-left: -40%;
        .links_heading_for_mobile_view {
            font-size: 16px;
            padding: 0.7rem 50% 0.7rem 0.7rem;
            
        }
        li {
            font-size: 15px;
            margin-top: 0.8rem;
            color: #2a85ad;
            transition: all 0.3s;
            &:hover {
                color: #14465c;
                
                cursor: pointer;
            }
            
        }
    }
    
}
@media (min-width:375.98px) and (max-width:550px) {
    .gap_between_each_primary_section {
        height: 34rem !important;
    }
    .links_heading_for_mobile_view{
        display: block;
    }
    .gap_between_each_primary_section {
        height: 25rem ;
    }
    .img-stack-1, .im, .img-stack-1__align_right {
        display: none;
    }
    .describe-content__align_right {
        margin-right: 10%;
        p {
            font-size: 13px;
        } 
    }
    .describe-content {
        margin-left: 40% !important;
        p {
            font-size: 13px;
        }
    }
    .img-stack-1 {
        margin-top: 12%;
    }
    .heading-img-main {
        text-align: right;
        // margin-top: 4rem;
        h1 {
            font-size: 33px;
        }
        h3 {
            font-size: 19px;
        }
    }
    .heading-img-main__align_right {
        .subheading__align_right {
            font-size: 18px;
        }
    }
    .container-11 {
        margin-top: 1rem;
        height: 72vh;
    }
}
@media (min-width:550.98px) and (max-width:699.98px) {
    .gap_between_each_primary_section {
        height: 16rem !important;
    }
    .container-11 {
        width: 88%;
        margin: 0 auto;  
    }
    .describe-content__align_right {
        margin-right: 33%;
        p {
            font-size: 13px;
        }
    }
    .describe-content {
        p {
            font-size: 13px;
        }
    }
    .img-stack-1 {
        margin-top: 12%;
    }
    .heading-img-main {
        text-align: right;
        h1 {
            font-size: 33px;
        }
        h3 {
            font-size: 19px;
        }
    }
    .heading-img-main__align_right {
        h1 {
            font-size: 33px;
        }
        .subheading__align_right {
            font-size: 18px;
        }
    }
    .heading-img-bottom, .heading_img_bottom__align_right {
        font-size: 27px;
    }
    .img-stack-1__align_right {
        .primary_area_imp_links {
            list-style: none;
            margin: 5% 0 0 28%;
            span {
                padding-right: 38% !important; 
            }
            .links_heading {
                padding: 1.2rem;
                text-transform: uppercase;
                font-family: $font-2;
                font-size: 17px;
                font-weight: 800;
            }
                li {
                    font-size: 15px;
                    font-family: $font-2;
                    font-weight: 600;
                    padding: 1.5rem 0 0 0;
                    width: 180px;
                    color: #2a85ad;
                    transition: all 0.3s;
                    &:hover {
                        color: #14465c;
                        
                        cursor: pointer;
                    }
                }
        }
    }
    .img-stack-1 {
        .primary_area_imp_links {
            list-style: none;
            margin: 5% 0 0 5%;
            span {
                padding-right: 20% !important; 
            }
            .links_heading {
                padding: 1.2rem;
                text-transform: uppercase;
                font-family: $font-2;
                font-size: 16px;
                font-weight: 800;
            }
                li {
                    font-size: 15px;
                    font-family: $font-2;
                    font-weight: 600;
                    padding: 1.5rem 0 0 0;
                    width: 180px;
                    color: #2a85ad;
                    transition: all 0.3s;
                    &:hover {
                        color: #14465c;
                        
                        cursor: pointer;
                    }
                }
        }
    }
}
@media (min-width:501px) and (max-width:550px) {
    .describe-content {
        margin-left: 19% !important;
    }
}
@media (min-width:700px) and (max-width:768px) {
    .container-11 {
        width: 83%;
        margin: 0 auto;
    }
    .img-stack-1__align_right {
        .im1 {
            margin-top: 14%;
        }
    }
    .heading-img-main {
        text-align: right;
        h1 {
            font-size: 30px;
            font-weight: 900;
        }
        h3 {
            font-size: 17px;
            margin-left: 27%;
            text-align: right;
            padding-top: 2%;
        }
    }
    .describe-content__align_right {
        p {
            font-size: 12px;
        }
    }
    .describe-content {
        p {
            font-size: 12px;
        }
    } 
    .img-stack-1__align_right {
        .primary_area_imp_links {
            list-style: none;
            margin: 5% 0 0 28%;
            span {
                padding-right: 40% !important; 
            }
            .links_heading {
                padding: 1.2rem;
                text-transform: uppercase;
                font-family: $font-2;
                font-size: 17px;
                font-weight: 800;
            }
                li {
                    font-size: 15px;
                    font-family: $font-2;
                    font-weight: 600;
                    padding: 1.5rem 0 0 0;
                    width: 240px;
                    color: #2a85ad;
                    transition: all 0.3s;
                    &:hover {
                        color: #14465c;
                        
                        cursor: pointer;
                    }
                }
        }
    }
    .img-stack-1 {
        margin-top: -2%;
        .primary_area_imp_links {
            list-style: none;
            margin: 5% 0 0 -2%;
            span {
                padding-right: 20% !important; 
            }
            .links_heading {
                padding: 1.2rem;
                text-transform: uppercase;
                font-family: $font-2;
                font-size: 17px;
                font-weight: 800;
            }
                li {
                    font-size: 15px;
                    font-family: $font-2;
                    font-weight: 600;
                    padding: 1.5rem 0 0 0;
                    width: 240px;
                    color: #2a85ad;
                    transition: all 0.3s;
                    &:hover {
                        color: #14465c;
                        
                        cursor: pointer;
                    }
                }
        }
    }
}

@media (min-width:767.98px) and (max-width:810px) {
    .container-11 {
        width: 83%;
        margin: 0 auto;
    }
    .img-stack-1 {
        .im1 {
            width: 44%;
            margin-top: 2%;
        }
    }
    .describe-content__align_right {
        p {
            font-size: 13px;
        }
    }
    .describe-content {
        p {
            font-size: 13px;
        }
    } 
    .img-stack-1__align_right {
        .primary_area_imp_links {
            list-style: none;
            margin: 5% 0 0 28%;
            span {
                padding-right: 40% !important; 
            }
            .links_heading {
                padding: 1.2rem;
                text-transform: uppercase;
                font-family: $font-2;
                font-size: 17px;
                font-weight: 800;
            }
                li {
                    font-size: 15px;
                    font-family: $font-2;
                    font-weight: 600;
                    padding: 1.5rem 0 0 0;
                    width: 200px;
                    color: #2a85ad;
                    transition: all 0.3s;
                    &:hover {
                        color: #14465c;
                        
                        cursor: pointer;
                    }
                }
        }
    }
    .img-stack-1 {
        .primary_area_imp_links {
            list-style: none;
            margin: 5% 0 0 -2%;
            span {
                padding-right: 20% !important; 
            }
            .links_heading {
                padding: 1.2rem;
                text-transform: uppercase;
                font-family: $font-2;
                font-size: 17px;
                font-weight: 800;
            }
                li {
                    font-size: 15px;
                    font-family: $font-2;
                    font-weight: 600;
                    padding: 1.5rem 0 0 0;
                    width: 220px;
                    color: #2a85ad;
                    transition: all 0.3s;
                    &:hover {
                        color: #14465c;
                        
                        cursor: pointer;
                    }
                }
        }
    }
}
@media (min-width:810px) and (max-width:992px) {
    .img-stack-2 {
        img {
            margin-left: 18%;
            width: 71%;
        }
    }
    .img-stack-1 {
        .im1 {
            margin-top: 12%;
        }
    }
    
    .heading-img-main {
        text-align: right;
        h1 {
            font-size: 50px;
        }
        h3 {
            font-size: 25px;
        }
    }
    .container-11 {
        width: 90%;
        margin: 0 auto;
    }
    .heading-img-bottom { /*WE BELIEVE*/
        font-size: 30px;
    }
    .heading_img_bottom__align_right {/*WE BELIEVE WITH ALIGN RIGHT*/
        font-size: 30px;
        margin-left: 3%;
    }
    .describe-content__align_right {
        margin-right: 40%;
        margin-left: 20px;
    }
    .img-stack-1__align_right {
        .primary_area_imp_links {
            list-style: none;
            margin: 5% 0 0 28%;
            span {
                padding-right: 40% !important; 
            }
            .links_heading {
                padding: 1.2rem;
                text-transform: uppercase;
                font-family: $font-2;
                font-size: 17px;
                font-weight: 800;
            }
                li {
                    font-size: 15px;
                    font-family: $font-2;
                    font-weight: 600;
                    padding: 1.5rem 0 0 0;
                    width: 230px;
                    color: #2a85ad;
                    transition: all 0.3s;
                    &:hover {
                        color: #14465c;
                        
                        cursor: pointer;
                    }
                }
        }
    }
    .img-stack-1 {
        .primary_area_imp_links {
            list-style: none;
            margin: 5% 0 0 6%;
            span {
                padding-right: 20% !important; 
            }
            .links_heading {
                padding: 1.2rem;
                text-transform: uppercase;
                font-family: $font-2;
                font-size: 17px;
                font-weight: 800;
            }
                li {
                    font-size: 15px;
                    font-family: $font-2;
                    font-weight: 600;
                    padding: 1.5rem 0 0 0;
                    width: 230px;
                    color: #2a85ad;
                    transition: all 0.3s;
                    &:hover {
                        color: #14465c;
                        
                        cursor: pointer;
                    }
                }
        }
    }
}
@media screen and (min-width:830px) {
    .container-11 {
        width: 80%;
    }
    .img-stack-1 {
        margin-top: -10%;
        img {
            margin-left: 7%;
            width: 50%;
        }
    }
    .img-stack-2 {
        img {
            margin-left: 22%;
            width: 66%;
        }
    }
    .img-stack-2__align_right {
        margin-top: 2%;
        margin-left: 4%;
        img {
            width:70%;
        }
    }
    .img-stack-1__align_right {
        margin-top: 28%;
        margin-left: -4%;
        img {
            width:80%;
        }
    }
    .heading-img-main {
        text-align: right;
        h1 {
            font-size: 50px;
            font-weight: 900;
        }
        h3 {
            font-size: 30px;
            margin-left: 27%;
            text-align: right;
            padding-top: 2%;
        }
    }

    .heading-img-bottom { /*WE BELIEVE*/
        margin-left: 10%;
        margin-top: 1%;
        font-size: 30px;
    }
    .heading_img_bottom__align_right {/*WE BELIEVE WITH ALIGN RIGHT*/
        margin-top: 1%;
        font-size: 46px;
    }
    .describe-content__align_right {
        margin-right: 40%;
    }
    .img-stack-1__align_right {
        .im1 {
            width: 90%;
            margin-top: 5%;
        }
        .primary_area_imp_links {
            list-style: none;
            margin: 5% 0 0 22%;
            span {
                padding-right: 40% !important; 
            }
            .links_heading {
                padding: 1.2rem;
                text-transform: uppercase;
                font-family: $font-2;
                font-size: 17px;
                font-weight: 800;
            }
                li {
                    font-size: 16px;
                    font-family: $font-2;
                    font-weight: 600;
                    padding: 1.5rem 0 0 0;
                    width: 350px;
                    color: #2a85ad;
                    transition: all 0.3s;
                    &:hover {
                        color: #14465c;
                        
                        cursor: pointer;
                    }
                }
        }
    }
    .img-stack-1 {
        .primary_area_imp_links {
            list-style: none;
            margin: 5% 0 0 -2%;
            span {
                padding-right: 20% !important; 
            }
            .links_heading {
                padding: 1.2rem;
                text-transform: uppercase;
                font-family: $font-2;
                font-size: 17px;
                font-weight: 800;
            }
                li {
                    font-size: 16px;
                    font-family: $font-2;
                    font-weight: 600;
                    padding: 1.5rem 0 0 0;
                    width: 260px;
                    color: #2a85ad;
                    transition: all 0.3s;
                    &:hover {
                        color: #14465c;
                        
                        cursor: pointer;
                    }
                }
        }
    }
}
@media (min-width:992px) and (max-width:1202px) {
    .container-11 {
        width:70%;
        margin: 0 auto;
    }
    .img-stack-1 {
        margin-top: 0;
        .im1 {
            
            width: 48%;
            // margin-top: 5%;
        }
        li {
            width: 200px;
        }
    }
    .heading-img-main {
        h1 {
            font-size: 50px;
        }
        h3 {
            // font-size: 20px;
        }
    }
    .heading-img-main__align_right {
        h1 {
            font-size: 50px;
        }
        
    }
    
    .img-stack-1__align_right {
        .im1 {
            width: 90%;
            margin-top: 5%;
        }
        .primary_area_imp_links {
            list-style: none;
            margin: 5% 0 0 28%;
            span {
                padding-right: 40% !important; 
            }
            .links_heading {
                padding: 1.2rem;
                text-transform: uppercase;
                font-family: $font-2;
                font-size: 17px;
                font-weight: 800;
            }
                li {
                    font-size: 16px;
                    font-family: $font-2;
                    font-weight: 600;
                    padding: 1.5rem 0 0 0;
                    width: 240px;
                    color: #2a85ad;
                    transition: all 0.3s;
                    &:hover {
                        color: #14465c;
                        
                        cursor: pointer;
                    }
                }
        }
    }
    .img-stack-1 {
        .primary_area_imp_links {
            list-style: none;
            margin: 5% 0 0 -2%;
            span {
                padding-right: 20% !important; 
            }
            .links_heading {
                padding: 1.2rem;
                text-transform: uppercase;
                font-family: $font-2;
                font-size: 17px;
                font-weight: 800;
            }
                li {
                    font-size: 16px;
                    font-family: $font-2;
                    font-weight: 600;
                    padding: 1.5rem 0 0 0;
                    width: 250px;
                    color: #2a85ad;
                    transition: all 0.3s;
                    &:hover {
                        color: #14465c;
                        
                        cursor: pointer;
                    }
                }
        }
    }
}

@media screen and (min-width:1202.98px) {
    .heading-img-main {
        text-align: right;
        h1 {
            font-size: 66px;
            font-weight: 900;
        }
        h3 {
            font-size: 35px;
            margin-left: 27%;
            text-align: right;
            padding-top: 2%;
        }
    }
    .heading-img-main__align_right {
        h1 {
            font-size: 66px;
        }
        h3 {
            font-size: 35px;
        }
    }
    .img-stack-1 {
        .im1 {
            width: 44%;
            margin-top:13%;
            margin-left: 3%;
        }
        .primary_area_imp_links {
            list-style: none;
            margin: 5% 0 0 7%;
            span {
                padding-right: 20% !important; 
            }
            .links_heading {
                padding: 1.2rem;
                text-transform: uppercase;
                font-family: $font-2;
                font-size: 17px;
                font-weight: 800;
            }
                li {
                    font-size: 16px;
                    font-family: $font-2;
                    font-weight: 600;
                    padding: 1.5rem 0 0 0;
                    width: 250px;
                    color: #2a85ad;
                    transition: all 0.3s;
                    &:hover {
                        color: #14465c;
                        
                        cursor: pointer;
                    }
                }
        }
    }
    .img-stack-2 {
        img {
            margin-left: 18%;
            width: 76%;
        }
    }
    .container-11 {
        width: 60%;
        margin: 0 auto;
    }
    .heading-img-bottom { /*WE BELIEVE*/
        margin-left: 10%;
        margin-top: 1%;
        font-size: 46px;
    }
    .heading_img_bottom__align_right {/*WE BELIEVE WITH ALIGN RIGHT*/
        margin-top: 1%;
        font-size: 46px;
    }
    .describe-content__align_right {
        margin-right: 40%;
    }
    .img-stack-1__align_right {
        .im1 {
            margin-top: -19%;
            width: 80%;
        }
        .primary_area_imp_links {
            list-style: none;
            margin: 5% 0 0 22%;
            span {
                padding-right: 40% !important; 
            }
            .links_heading {
                padding: 1.2rem;
                text-transform: uppercase;
                font-family: $font-2;
                font-size: 17px;
                font-weight: 800;
            }
                li {
                    font-size: 16px;
                    font-family: $font-2;
                    font-weight: 600;
                    padding: 1.5rem 0 0 0;
                    width: 250px;
                    color: #2a85ad;
                    transition: all 0.3s;
                    &:hover {
                        color: #14465c;
                        
                        cursor: pointer;
                    }
                }
        }
    }
    
}
