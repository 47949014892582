.pillar-page{
    margin-top: 5rem;
    width: 60%;
    margin: 10rem auto;
    &-item{
        // background-color: #ccc;
        border: 0.3rem solid rgba(#ccc, 0.2);
        margin: 3rem auto;
        padding: 1rem;
        &-heading{
            font-family: $font-6;
            font-weight: 500;
            font-size: 2.4rem;
            text-align: left;
            margin-top: 2rem;
            margin-left: 2rem;
            // background-color: red;

        }

        &-image{
            max-width: 55%;
            margin: 2rem auto;
        }

        &-body{
            width: 94%;
            margin: 0 auto;
            font-family: $font-6;
            font-size: 1.4rem;
            font-weight: 400;
        }
    }

    .pillar-name {
        font-size: 40px;
        text-align: center;
    }
}


@media (max-width:800px) {
    .pillar-page {
        width: 70%;
    }
}

@media (max-width:700px) {
    .pillar-page {
        width: 90%;

        &-item {
            &-image {
                width: 92%;
            }
        }

        &-heading{
            margin-left: 1rem;
        }
    }
}