.team_page_container {
    padding-top: 6rem;
    margin-bottom: 6rem;
    h1 {
        padding: 1rem;
        font-size: 36px;
        font-family: $font-6;
        font-weight: 600;
    }

    .team_designation_heading {
        padding-left: 2rem;
        margin-top: 4rem;
        margin-bottom: 2.5rem;
        font-size: 24px;
        span {
            // border-bottom: 3px solid rgba(rgb(20, 103, 151), 0.7);
        }
    }
}
.team_card_button {
    transition: all 0.3s;
    color:#2a85ad;
    // padding: 1rem;
    font-weight: 600;
    &:hover {
        color: #14465c;
        .fa-external-link {
            padding-left:0.9rem !important;
        }
    }
}

.team_card-wrapper {
    margin: 1.2rem;
    background-color: rgba(#f3f3f3, 1);
    transition: all 0.3s;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
        transform: scale(1.02);
    }
}
.partner_cards_container {
    padding-top: 6rem;
}
// .team_card_body {
//     // background-color: rgba(white, 0.8);
//     padding-left: 2rem;
// }
.team_card {
    height: 30.2rem;
    transition: all 0.3s;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    // &:hover {
    //     box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    //     transform: scale(1.02);
    // }
    h5 {
        font-family: $font-6;
        font-weight: 600;
        color: rgba(black, 0.7);
        font-size: 16px;
        margin-top: 1.3rem;
        

    }
    img {
        width: 100%;
        height: 20rem;
        object-fit: contain;
        
        
    }
    

}
.fa-external-link {
    animation-name:externalLink;
    animation-duration:0.5s;
    animation-direction: alternate;
    transition: all 0.3s ease-in-out; 
}
@keyframes externalLink {
    from {
        padding-left:0rem !important;
    }
    to {
        padding-left:0.9rem !important;
    }
}
