.footer {
    padding: 0;
    background: rgba(#414141, 1);
    color: rgba(white, 0.7);
    p {
        font-size: 16px;
    }
    ul {
        list-style: none;
        li {
            padding: 0.4rem;
        }
        span {
            font-size: 16px;
            position: relative;
            color: rgba(white, 0.9);

            transition: all 0.3s;
            &:hover {
                cursor: pointer;
                color:#ffffff;
            }
            // &::before {
            //     // content: "";
            //     // position: absolute;
            //     // width: 100%;
            //     // height: 1px;
            //     // bottom: 0;
            //     // left: 0;
            //     // background-color:#2CC466;
            //     // visibility: hidden;
            //     // transform: scaleX(0);
            //     // transition: all 0.2s ease-in-out;

            //     content: "";
            //     position: absolute;
            //     width: 0;
            //     height: 1px;
            //     bottom: 0;
            //     left: 0;
            //     background-color:#2CC466;
            //     visibility: hidden;
            //     transition: all 0.5s ease-in-out;
            //     }
                &:hover::before {
                    visibility: visible;
                    width: 100%;
                }
        }
    }

    //footer form
    .newsletter_form {
        padding: 2rem;
        color: black;
        margin-top: -1.1rem;
        // background: linear-gradient(90deg, #b3b3b3 0%, #939494 100%);
        background-color: rgb(245, 245, 245);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
        // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        .newsletter_form_content {
            width: 140%;
        }
        h1 {
            font-family: $font-9;
            margin-bottom: 1.5rem;
            font-size: 26px;
            // font-weight: 600;
        }
        input {
            background-color: #f9f9f9;
            height: 3.4rem;
            border-top-right-radius: 0rem;
            border-bottom-right-radius: 0rem;
            font-size: 14px;
            &:focus {
                
            }
        }
        button {
            outline: none !important;
            border: none;
            padding: 0.8rem;
            background-color:#ececec;
            border: 2px solid #e4e4e4;
            // font-size: 11px;
            color: #828282;
            // width: 10rem;
            // border-top-right-radius: 0.3rem;
            // border-bottom-right-radius: 0.3rem;
            // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            transition: all 0.3s;
            &:hover {
                cursor: pointer;
                background-color:#dddcdc;
            }
        }
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 1rem;
        h2 {
            font-size: 20px;
            font-family: $font-9;
            margin: 1.5rem 1.5rem 1.5rem 0;
            font-weight: 600;
        }
        .paper_clip {
            position: absolute;
            right: 26px;
            top: -14px;
        }
        .page_dot {
            position: absolute;
            right: 20px;
            top: -5px;
        }
    }
    .social_media_links_container {
        .social_media_links {
            img {
                width: 50px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .fa {
            opacity: 0.8;
            &:hover {
                opacity: 1;
                cursor: pointer;
            }
        }
    }
    .footer_top_row {
        display: flex;
        justify-content: center;
        // align-items: center;
        .social_media_links_container {
            padding-top: 2rem;
        }
    }

}


// .form_:after, .form_:after {
//     content: '';
//     position: absolute;
//     width: 0;
//     height: 0;
//     border-bottom: 1.13rem solid #b4b4b4;
//     border-left: 1rem solid transparent;
//     // bottom: 10px;
//     left: 5px;
//     top: -11px;
// }
.container-row {
    width: 80% !important;
    margin: 0 auto !important;
    font-family: $font-3;
}

.footer_links_section {
    a {
        color: rgba(rgb(51, 51, 51), 0.9);
    }
}
@media (min-width:121px) and (max-width:374.98px) {
    .footer {

        .footer-last-line {
            max-width: 90%;
            margin: 0 auto;
        }
        .form_ {
            padding: 0;
        }
        .newsletter_form {
            .newsletter_form_content {
                width: 90%;
                input {
                    width: 10.5rem;
                }
            }
            margin: 2rem auto;
            border-radius: 0.2rem;
            // width: 130%;
            h1 {
                font-size: 16px;
            }
            h2 {
                font-size: 15px;
            }
            .page_dot {
                position: absolute;
                right: 2.3rem;
                top: 2.3rem;
                color: black;
            }
            .paper_clip {
                position: absolute;
                right: 2.4rem;
                top: 1.55rem;
            }
        }
        .footer_top_row {
            
            // align-items: center;
            .social_media_links_container {
                padding-top: 2rem;
                .row {
                    flex-direction: row;
                    align-items: center;
                }
            }
        }
        
    }
    .form_:after, .form_:after {
        display: none;
    }
    .footer_bottom_row {
        padding: 0.2rem;
        h1 {
            font-size: 18px;
        }
        h2 {
            font-size: 14px;
        }
        p {
            font-size: 12px;
        }
    }
    .footer_links_section {
        span {
            font-size: 12px !important;
        }
    }
}
@media (min-width:375px) and (max-width:549.98px) {
    .footer {
        .footer-last-line {
            max-width: 74%;
            margin: 0 auto;
        }
        .newsletter_form {
            .newsletter_form_content {
                width: 80%;
                input {
                    width: 13rem;
                }
            }
            margin-top: 2rem;
            border-radius: 1.5rem;
            h1 {
                font-size: 18px;
            }
            h2 {
                font-size: 16px;
            }
            .page_dot {
                position: absolute;
                right: 3rem;
                top: 2.3rem;
                color: black;
            }
            .paper_clip {
                position: absolute;
                right: 3.2rem;
                top: 1.55rem;
            }
            input {
                width: 90%;
            }
        }
        
    }
    .form_:after, .form_:after {
        display: none;
    }
    .footer_bottom_row {
        h1 {
            margin-top: 2.6rem;
            font-size: 19px;
        }
        h2 {
            font-size: 14px;
        }
        p {
            font-size: 13px;
        }
    }
    .footer_links_section {
        span {
            font-size: 12px !important;
        }
    }
}
@media (min-width:550px) and (max-width:629.98px) {
    .footer {
        .social_media_links_container {
            .row {
                margin-left: 0;
                width: 30%;
            }
        }
        .newsletter_form {
            .newsletter_form_content {
                width: 80%;
                // input {
                //     width: 13rem;
                // }
            }
            margin-top: 2rem;
            border-radius: 1.5rem;
            h1 {
                font-size: 18px;
            }
            h2 {
                font-size: 16px;
            }
            .page_dot {
                position: absolute;
                right: 2.3rem;
                top: 2rem;
                color: black;
            }
            .paper_clip {
                position: absolute;
                right: 2.5rem;
                top: 1.55rem;
            }
            input {
                width: 80%;
            }
        }
        // .container-row {
        //     .footer_bottom_links {
        //         display: flex;
        //         flex-direction: row;
        //     }
        // }    
        
    }
    
    .form_:after, .form_:after {
        display: none;
    }
    .footer_bottom_row {
        h1 {
            font-size: 19px;
        }
        h2 {
            font-size: 14px;
        }
        p {
            font-size: 13px;
        }
    }
    .footer_links_section {
        span {
            font-size: 12px !important;
        }
    }
    
}
@media (min-width:630px) and (max-width:767px) {
    .footer {
        .newsletter_form {
            .newsletter_form_content {
                width: 80%;
                // input {
                //     width: 13rem;
                // }
            }
            margin-top: 2rem;
            border-radius: 1.5rem;
            h1 {
                font-size: 18px;
            }
            h2 {
                font-size: 16px;
            }
            input {
                width: 25rem;
            }
            .page_dot {
                position: absolute;
                right: 3rem;
                top: 2.3rem;
                color: black;
            }
            .paper_clip {
                position: absolute;
                right: 3.5rem;
                top: 1.55rem;
            }
        }
        
    }
    .form_:after, .form_:after {
        display: none;
    }
    .footer_links_section {
        display: flex;
        flex-direction: row;
        span {
            font-size: 14px !important;
        }
    }
    .footer_bottom_row {
        flex-direction: row;
        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 18px;
        }
        p {
            font-size: 14px;
        }
    }
}
@media (min-width:767.98px) and (max-width:829.98px) {
    .footer_bottom_row {
        
        h1 {
            margin-top: 3rem;
            font-size: 22px;
        }
        h2 {
            font-size: 17px;
        }
        p {
            font-size: 15px;
        }
    }
    .footer_links_section {
        margin-top: 2rem;
        span {
            font-size: 12px !important;
        }
    }
    .footer {
        .newsletter_form {
            .newsletter_form_content {
                width: 80%;
                // input {
                //     width: 13rem;
                // }
            }
            h1 {
                font-size: 18px;
            }
            h2 {
                font-size: 16px;
            }
            input {
                width: 20rem;
            }
            .paper_clip {
                position: absolute;
                right: 2.3rem;
                // top: 1.55rem;
            }
            .page_dot {
                position: absolute;
                right: 1.8rem;
                top: 0px;
            }
        }
        
    }
}
@media (min-width:830px) and (max-width:991.98px) {
    .footer {
        .newsletter_form {
            h1 {
                font-size: 20px;
            }
            h2 {
                font-size: 17px;
            }
        }
    }
    .footer_bottom_row {
        margin-top: 2rem;
    }
    .footer_links_section {
        span {
            font-size: 14px !important;
        }
    }
}

@media (max-width:1160px) {
    .footer {
        .newsletter_form {
            h1 {
                font-size: 20px;
            }
            h2 {
                font-size: 17px;
            }
        }
    }
    .footer_bottom_row {
        margin-top: 2rem;
    }
    .footer_links_section {
        margin-top: 2.3rem;
        span {
            font-size: 14px !important;
        }
    }
}

@media (min-width: 1200px) {
    .footer {
        .newsletter_form {
            h1 {
                font-size: 20px;
            }
            h2 {
                font-size: 17px;
            }
        }
    }
    .footer_bottom_row {
        h1 {
            margin-top: 4rem;
        }
    }
    .footer_links_section {
        margin-top: 2.3rem;
        span {
            font-size: 14px !important;
        }
    }
}