
.gallery_page_container {
    // background-color: rgba(grey, 0.2);
    background-color: rgba(grey, 0.2);
    padding-bottom: 8rem;
    .gallery_page_main_wrapper {
        
        .gallery_page_banner {
            height: 35vh;
            // width: 100%;
            margin-bottom: 6rem;
            // background-image: url("../../assets/back_1.svg");
            background-repeat: no-repeat;
            background-size: cover; 
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            h1 {
                position: absolute;
                top: 50%;
                color: rgba(white, 0.9);
                font-size: 4rem;
                font-family: $font-9;
            }
        }
    }
}
.gallery_container {
    
    z-index: -1;
}

.gallery {
    background-color: rgba(white, 0.9);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    // height: 100vh;
    img {
        transition: all 0.3s;
        // position: absolute;
        clip-path: inset(0.9rem 0.9rem);
        z-index: 1;
    }
    h4 {
        transition: all 0.3s;
        position: absolute;
        left: -0.1rem;
        bottom: 0;
        padding: 0.6rem;
        background-color: rgba(black, 0.7);
        color: rgba(white, 0.9);
        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-bottom: 0.6rem solid rgba(black, 0.68);
            border-left: 1rem solid transparent;
            // bottom: 10px;
            left: 1px;
            top: -6px;
        }
    }
    .image_overlap {
        position: absolute;
        left: 1rem;
        top: 1rem;
        right: 1rem;
        bottom: 1rem;
        background-color: rgba(white, 0.9);
        z-index: -1;
    }
    &:hover {
        img {
            clip-path: inset(-0.05rem -0.05rem);
        }
        h4 {
            padding-left: 1.4rem;
        }
    }
}