.primary-functional-area-container {
    margin-top: 5rem;
    margin-bottom: 10rem;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    &-column-left {
        display: flex;
        flex-direction: column;
        flex-basis: 30%;
        justify-content: center;
    }
    &-column-right {
        display: flex;
        flex-direction: column;
    }
    &-heading {
        font-size: 65px;
        font-family: $font-12;
        text-align: right;
        font-weight: 700;
        display: block;
        margin-bottom: 0;
        line-height: 10rem;
    }
    &-subheading {
        font-size: 3.5rem;
        font-family: $font-12;
        font-style: italic;
        font-weight: 300;
        text-align: right;
        margin-bottom: 4rem;
    }
    &-description {
        font-size: 2rem;
        text-align: right;
        font-weight: 300;
        font-family: $font-13;
        width: 90%;
        float: right;
        margin-right: 0;
        margin-left: auto;
    }
    &-mid-image {
        flex-shrink: 1;
        width: 100%;
    }

    &-front-image {
        transform : translateX(4rem);

    }

    &-list{
        list-style: none;
        font-family: $font-13;
        font-size: 2rem;
        width: 100%;
        padding: 1rem 3rem;
        padding-bottom: 0;
        font-weight: 700;
        align-self: end;
        justify-self: self-end;
    }
    &-list-heading{
        // background-color: black;
        // color: white;
        font-family: Open Sans;
        font-weight: 700;
        font-size: 3rem;
    }
    &-list-point{
        // background-color: yellowgreen;
        // padding: 0.2rem 0.8rem;
        font-family: Open Sans;
        font-weight: 100;
        border-bottom: 1px solid rgba(black,0.5);
        // padding-bottom: 1rem;
        padding-top: 1rem;
        color: black;
    }
}


.primary-functional-area-container-left {
    margin-top: 5rem;
    margin-bottom: 10rem;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    &-column-left {
        display: flex;
        flex-direction: column;
        flex-basis: 30%;
        justify-content: center;
    }
    &-column-right {
        display: flex;
        flex-direction: column;
    }
    &-heading {
        font-size: 65px;
        font-family: $font-12;
        text-align: left;
        font-weight: 700;
        display: block;
        margin-bottom: 0;
        line-height: 10rem;
    }
    &-subheading {
        font-size: 3.5rem;
        font-family: $font-12;
        font-style: italic;
        font-weight: 300;
        text-align: left;
        margin-bottom: 4rem;
    }
    &-description {
        font-size: 2rem;
        text-align: left;
        font-weight: 300;
        font-family: $font-13;
        width: 90%;
        float: left;
        margin-right: auto;
        margin-left: 0;
    }
    &-mid-image {
        flex-shrink: 1;
        width: 100%;
    }

    &-front-image {
        transform : translateX(-4rem);

    }

    &-list{
        list-style: none;
        font-family: $font-13;
        font-size: 2rem;
        width: 100%;
        padding: 1rem 3rem;
        padding-bottom: 0;
        font-weight: 700;
        align-self: end;
        justify-self: self-start;
    }
    &-list-heading{
        // background-color: black;
        // color: white;
        font-family: Open Sans;
        font-weight: 700;
        font-size: 3rem;
    }
    &-list-point{
        // background-color: yellowgreen;
        // padding: 0.2rem 0.8rem;
        font-family: Open Sans;
        font-weight: 100;
        border-bottom: 1px solid rgba(black,0.5);
        // padding-bottom: 1rem;
        padding-top: 1rem;
        font-size: 2rem;
        color: black;
    }
}

@media (min-width:1000px) and (max-width:1150px) {
    .primary-functional-area-container {
        width: 90%;
        transform: scale(0.85)
    }
}

@media (min-width:800px) and (max-width:1000px) {
    .primary-functional-area-container {

    }
}

@media (min-width:600px) and (max-width:768px) {
    .primary-functional-area-container {
        width: 90%;
        transform: scale(0.85)
    }
}