.Modal {
    position: fixed;
    z-index: 5000;
    top: 50%;
    left: 50%;
    transition: all 0.3s ease-out;
}

.Modal img{
    /* border: 1rem solid yellow; */
    position: absolute;
    /* top: 50%; */
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80vh;
    max-width: 80vw;
    /* display: none; */
}

@media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}