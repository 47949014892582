.success_stories_background {
    background-color: rgba(grey, 0.2);
}
.success_stories_wrapper {

}
.story_card_container {
    padding-bottom: 6rem;
}
.success_stories_banner {
    // background-image: url("../../assets/back_1.svg");
    background-repeat: no-repeat;
    background-size: cover; 
    height: 35vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    //success stories banner heading
    h1 {
        position: absolute;
        top: 50%;
        margin-bottom: 0;
        color: rgba(white, 0.9);
        font-size: 4rem;
        font-family: $font-9;
    }
}

.story_Page_card_template {
    margin: 1.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(white, 0.9);
    transform: translateY(0.6rem);
    transition: all 0.3s ease;
    overflow: hidden;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
        transform: translateY(0);
    }

    //story card image
    .story_page_card_image {
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
        object-fit: contain;
        // margin-top: 0;
        margin: 0;
    }

    //story card title
    h2 {
        -webkit-backface-visibility:hidden;
        color: rgba(black, 0.9);
        font-size: 20px;
        font-weight: 700;
        margin-top: 1rem;
        text-align: center;
        padding: 0;
    }
    p {
        font-size: 13px;
        // padding: 1.3rem;
        max-width: 40rem;
        margin: 1.4rem auto;
        color: rgba(black, 0.8);
        font-weight: 600;
        text-align: center;
        // background: linear-gradient(180deg, #0e0e0e 28%, rgba(65, 64, 64, 0.7) 93.46%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
    }

    //learn more
    h6 {
        margin: 0;
        font-size: 14px;
        padding: 1.5rem;
        color: #63a4ca;
        transition: all 0.3s ease;
        span {
            padding: 0.2rem;
            &:hover {
                background-color: rgba(#2a7eaf, 0.2);
            }
        }
    }
}
