body {
  overflow: overlay;
}
::-webkit-scrollbar {
    overflow-y: auto; 
    width: 10px;
    z-index: 1000;
    height: 10px; 
}

::-webkit-scrollbar-thumb {
  overflow: overlay;
    background:rgba(#2e8b57, 1); 
    border-radius: 2px;
}
  
::-webkit-scrollbar-thumb:hover {
  overflow: overlay;
  background:rgba(#2e8b57, 1); 
}