.testimonial-carousel{
    padding: 3rem;
    background: rgba(white, 1);
    margin: auto;

    h1{
        font-family: $font-6;
        font-size: 3.6rem;
        font-weight: 700;
    }
    p{
        font-family: $font-2;
        font-size: 1.8rem;
        width: 80%;
        // min-width: 25rem;
        margin: 1.6rem auto;
    }
    
    img{
        position: relative;
        width: 30% !important;
        min-width: 25rem;
        margin-top: 1rem;
        // background-color: red;
        border-radius: 50%;
        border: 0.4rem solid #FF8008 !important;
    }

    .control-dot{
        background-color: white;
    }
    .content {
        height: 70vh;
    }
}
.testimonial_container {
    margin: 10rem 0;
}
.testimonials_heading {
    text-align: left;
    font-size: 4.8rem;
    font-weight: 700;
    font-family: $font-6;
    margin: 4rem 0 4rem 10rem;
}
.carousel-slider {
    .dot {
        box-shadow: none !important;
        background-color: rgba(black, 0.8) !important;
        outline: none !important;
    }
    
}
@media (min-width:121px) and (max-width: 259.98px) {
    .testimonial-carousel {
        height: 100vh;
        .content {
            h1 {
                font-size: 20px;
            }
            h2 {
                font-size: 18px;
            }
            // p {
            //     font-size: 16px;
            // }
        }
        img{
            min-width: 10rem;
            margin: 0 auto;
        }
    }
    .testimonials_heading {
        text-align: left;
        font-size: 2.5rem;
        margin: 4rem 0 4rem 2rem;
    }
}
@media (min-width:260px) and (max-width: 374.98px) {
    .testimonial-carousel {
        height: 80vh;
        .content {
            h1 {
                margin-top: 1.3rem;
                font-size: 20px;
            }
            h2 {
                font-size: 15px;
            }
            p {
                margin-top: 2rem;
                border-top: 1px solid rgba(black, 0.2);
                padding-top: 2rem;
                font-size: 13.5px;
                width: 100%;
            }
        }
        img{
            min-width: 15rem;
        }
        
    }
    .testimonials_heading {
        text-align: left;
        font-size: 3rem;
        margin: 4rem 0 4rem 0rem;
    }
}
@media (min-width:375px) and (max-width: 550px) {
    .testimonial-carousel {
        .content {
            h1 {
                margin-top: 1.3rem;
                font-size: 23px;
            }
            h2 {
                font-size: 18px;
            }
            p {
                margin-top: 2rem;
                padding-top: 2rem;
                border-top: 1px solid rgba(black, 0.2);
                font-size: 15px;
                width: 100%;
            }
        }
        img{
            min-width: 22rem;
        }
        height: 82vh !important; 
    }
    .testimonials_heading {
        text-align: left;
        font-size: 3.5rem;
        margin: 4rem 0 4rem 4.3rem;
    }
    
}

@media (min-width:550.98px) and (max-width: 767.98px) {
    .testimonial-carousel {
        .content {
            h1 {
                margin-top: 1.3rem;
                font-size: 23px;
            }
            h2 {
                font-size: 18px;
            }
            p {
                margin-top: 2rem;
                padding-top: 2rem;
                border-top: 1px solid rgba(black, 0.2);
                font-size: 16px;
                width: 100%;
            }
        }
        img{
            min-width: 22rem;
        }
        height: 82vh !important; 
    }
    .testimonials_heading {
        text-align: left;
        font-size: 3.5rem;
        margin: 4rem 0 4rem 4.3rem;
    }
    
}
@media (min-width:768px) and (max-width: 992px) {
    .testimonial-carousel {
        .content {
            h1 {
                margin-top: 1.3rem;
                font-size: 23px;
            }
            h2 {
                font-size: 18px;
            }
            p {
                margin-top: 2rem;
                padding-top: 2rem;
                border-top: 1px solid rgba(black, 0.2);
                font-size: 16px;
                width: 100%;
            }
        }
        img{
            min-width: 22rem;
        }
    }
    .testimonials_heading {
        text-align: left;
        font-size: 3.5rem;
        margin: 4rem 0 4rem 4.3rem;
    }
    
}
@media (max-width: 1199px) {
    .testimonial-carousel {
        height: 80vh !important;
        .content {
            
        }
        
    }
}

@media (min-width: 1202px) {
    .testimonial-carousel {
        height: 80vh !important;
        .content {
            
            h1 {
                margin-top: 1.3rem;
                font-size: 23px;
            }
            h2 {
                font-size: 18px;
            }
            p {
                margin-top: 2rem;
                padding-top: 2rem;
                border-top: 1px solid rgba(black, 0.2);
                font-size: 16px;
                // width: 100%;
                min-width: 25rem;
            }
            img{
                width: 70%;
            }
        }
        
    }
}