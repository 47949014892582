.blog-list-wrapper {
    background-color: rgb(250, 250, 250);
}

.blog-list-container {
    padding-top: 12rem;
    padding-bottom: 10rem;
    width: 66% !important;
    
    .blog-card-image {
        width: 100%;
        height: 100%;
        object-fit: cover;

    }

    .blog-card-content {
        font-size: 15px;
    }

}

.each-blog-item {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin-top: 2.5rem;
    padding: 0.7rem;
    background-color: white;

    .blog-heading {
        padding-left: 1.4rem;
        margin-bottom: 1.4rem;
        font-size: 25px;
    }
    .blog-date {
        padding-left: 1.4rem;
        font-size: 14px;
        color: grey;
        margin-bottom: 1.4rem;
    }

    .read-more {

    }
}

.each-blog-page {
    min-height: 70vh;
    background-color: rgb(245, 245, 245);
    padding-top: 10rem;
    padding-bottom: 10rem;
}
.each-blog-wrapper {

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: rgb(255, 255, 255);
    padding: 3rem;
    max-width: 60% !important;
    .each-blog-heading {
        font-size: 23px;
        margin-left: 4rem;
    }

    .each-blog-date {
        font-size: 17px;
        margin-left: 4rem;
        margin-bottom: 2.5rem;
        color: gray;
    }

    .each-blog-banner-image {
        width: 60%;
        padding: 1.5rem;
        margin-bottom: 1rem;
    }

    .each-blog-content {
        margin-top: 3.5rem;
        font-size: 15px;
        max-width: 90%;
        margin: 0 auto;
    }

    .each-blog-author {
        font-size: 14px;
        padding-left: 4rem;
    }
}

@media (max-width:500px) {
    .blog-list-container {
        width: 98% !important;
    }

    .each-blog-wrapper {
        max-width: 98% !important;
        padding: 1.6rem 0 1.6rem 0 !important;

        .each-blog-heading {
            font-size: 23px;
            margin-left: 1rem !important;
        }
    
        .each-blog-date {
            font-size: 17px;
            margin-left: 1rem !important;
            margin-bottom: 2.5rem;
            color: gray;
        }

        .each-blog-banner-image {
            width: 100%;
            padding: 1.5rem;
            margin-bottom: 1rem;
        }
    }
}