//village page main container (containing all cards), 
// location => src/components/village/VillageCardsContainer.jsx
.village_page_container {
    background-color: rgba(grey, 0.2);
    padding-bottom: 8rem;
    .village_page_main_wrapper {
        
        .village_page_banner {
            height: 35vh;
            // width: 100%;
            margin-bottom: 4rem;
            // background-image: url("../../assets/back_1.svg");
            background-repeat: no-repeat;
            background-size: cover; 
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            //village page banner heading
            h1 {
                position: absolute;
                color: rgba(white, 0.9);
                font-size: 4rem;
                font-family: $font-9;
                margin-top: 4rem;
            }
        }
    }
}


//Village page card (at route /village), location => src/common/villagePageCard.js
.village_Page_card_template {
    margin: 1.3rem;
    // padding: 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // height: 25rem;
    border-radius: 0.5rem;
    background-color: rgba(white, 0.9);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: all 0.2s;
    overflow: hidden;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
        transform: scale(1.02);
    }

    //village card image
    .village_page_card_image {
        -moz-transition: all 2s ease;
        -o-transition: all 2s ease;
        -webkit-transition: all 2s ease;
        margin: 0;
        padding: 0;
        &:hover {
            margin: 0;
            padding: 0;
        }
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
        height: 21rem;
        object-fit: cover;
    }

    //village card title
    h1 {
        -webkit-backface-visibility:hidden;
        color: rgba(black, 0.9);
        font-size: 17px;
        padding: 1rem;
        text-align: center;
        margin-bottom: 0;

    }
}