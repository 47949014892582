.contact_page_container {
    background-color: rgba(white, 0.9);
    display: flex;
    margin-top: 7%;
    height: 100vh;
    .contact_page_heading {
        font-size: 33px;
        color: rgb(18, 80, 214);
        span {
            border-bottom: 2.4px solid rgb(18, 80, 214);
        }
    }
    .contact_page_wrapper {
        border-radius: 4px;
        height: 40vh;
        border: 1px solid rgba(rgb(202, 202, 202), 0.5);
        // justify-content: center;
        padding: 1.5rem;
        background-color: rgba(rgb(202, 202, 202), 0.2);
        h1 {
            color: rgb(22, 137, 182);
            font-weight: 500;
            margin-top: 1.5rem;
            font-size: 32px;
            font-family: $font-2;
        }
        h2 {
            margin-top: 2.2rem;
            line-height: 1.6;
            font-size: 22px;
            font-family: $font-2;

            span {
                font-size: 20px;
                font-family: $font-11;
                a {
                    color: #2a85ad ;
                }
            }
        }
        h3 {
            margin-top: 1.8rem;
            line-height: 1.6;
            font-size: 22px;
            font-family: $font-2;
        }
        h4 {
            margin-top: 1.8rem;
            line-height: 1.6;
            font-size: 22px;
            font-family: $font-2;
        }
    }
    a {
        
        span {
            
            font-weight: 500;
            margin-top: 0.6rem;
            font-size: 16px;
            color: #2a85ad;
            &:hover {
                color: #174153;
            }
        }
    }
}