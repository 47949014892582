.pfa-mobile {
    padding: 0.75rem;
    margin-top: 2rem;
    margin-bottom: 2.5rem;
    h1 {
        font-size: 28px;
        font-family: $font-2;
    }
    h3 {
        font-size: 16px;
        font-display: $font-2;
    }

    &-content {
        font-size: 13px;
        font-family: $font-2;
    }

    &-highlights {
        list-style: none;
        padding-left: 0;
        margin-top: 1rem;
        li {
            font-size: 14px;
            color: rgb(45, 106, 156);
            font-weight: 600;
        }
    }

    &-highlight-heading {
        font-size: 17px;
        font-family: $font-2;
        font-weight: 600    ;
        margin-top: 1.3rem;
        padding: 0.3rem;
    }
}

@media (max-width:767.98px) {
    .pfa-desktop {
        display: none;
    }
}

@media (min-width:767.98px) {
    .pfa-desktop {
        display: block;
    }
    .pfa-mobile {
        display: none;
    }
}

@media (min-width:425px) and (max-width:505px) {
    .pfa-mobile-content-style-is-left {
        margin-right: 9.5rem;
    }
    .pfa-mobile-highlight-heading-style-is-left {
        margin-right: 9.5rem;
    } 
    .pfa-mobile-content-style-is-right {
        margin-left: 8.6rem;
    }
    .pfa-mobile-highlight-heading-style-is-right {
        margin-left: 8.6rem;
    } 
}

@media (min-width:375px) and (max-width:425px) {
    .pfa-mobile-content-style-is-left {
        margin-right: 4.5rem;
    }
    .pfa-mobile-highlight-heading-style-is-left {
        margin-right: 4.5rem;
    } 
    .pfa-mobile-content-style-is-right {
        margin-left: 3.4rem;
    }
    .pfa-mobile-highlight-heading-style-is-right {
        margin-left: 3.7rem;
    } 
}