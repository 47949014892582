.template-page2 {
    min-height: 100vh;
    position: relative;
    width: 100%;
    padding-top: 0rem;
    .banner-image {
        position: relative;
        padding: 0;
        // border: 1rem solid yellow;
        display: flex;
        .heading-container {
            position: absolute;
            bottom: 40%;
            left: -5rem;
            // background-color: rgba(76,162,71,0.8);
            padding: 1.5rem 4rem;
            padding-left: 20rem;

            .page-subheading {
                font-size: 3rem;
                color: $color-7;
                font-family: $font-9;
                font-weight: 400;
                text-transform: uppercase;
            }
            .page-heading {
                font-size: 6rem;
                color: $color-7;
                font-family: $font-9;
                font-weight: 600;
            }
        }
    }
    .left_img {
        background-image: $about_page_color; 
        width: 50%;
        height: 60rem;
        // opacity: 0.9;
        // border: 1rem solid blue;
    }

    img {
        // height: 70vh !important;
        object-fit:     cover;
        width: 50%;
        height: 60rem;
        z-index: -1;
        transform-origin: center center;
        animation: grow 1s linear;
        background-color: rgba(black,0.05);
    }
    .main-content-container {
        background-color: white;
        width: 80%;
        margin: auto;
        // box-shadow: -2px 2px 10px rgba(black,0.5);
        transform: translateY(-10rem);

        .main-content {
            .page-path {
                font-size: 2rem;
                margin: 2rem;
                padding: 2.5rem;
                text-transform: uppercase;
                font-family: $font-9;
                background: $about_page_color;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            h1 {
                font-size: 14.4rem;
                font-family: $font-6;
                font-weight: 800;
                padding: 0;
                margin: 0;
                transform: translateY(-90%);
                margin-left: 15rem;
                color: $color-7;
                text-transform: uppercase;
            }

            h2 {
                font-family: $font-9;
                font-size: 2.4rem;
                font-weight: 600;
                background: $about_page_color;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            p {
                font-size: 1.8rem;
                font-family: $font-9;
                margin-bottom: 4rem;
            }

            .latest-carousel {
                background: #fefefe;
                width: 100%;
                min-height: 20rem;

                box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
                padding: 1rem;

                p {
                    display: inline-block;
                    font-size: 2.4rem;
                    font-family: $font-9;
                    transition: all 0.5s;
                    // color: rgba(76,162,71,0.8);
                    border-bottom: 2px solid rgba(31, 114, 26, 0.3);
                }
                p:hover {
                    cursor: pointer;
                    transition: all 1s;
                    border-bottom: 3px solid rgba(76, 162, 71, 1);
                }

                .arrows {
                    color: rgba(76, 162, 71, 0.8);
                    float: right;
                    margin-right: 1rem;
                    font-weight: 700;
                    border-bottom: none;
                }
                .arrows:hover {
                    border-bottom: none;
                }

                .date {
                    font-family: $font-9;
                    font-size: 2rem;
                    margin-bottom: 1rem;
                }
                .latest-carousel-image {
                    width: 100%;
                    height: 20rem;
                }
                .update-content {
                    font-size: 1.8rem;
                    margin-top: 1rem;
                    font-family: $font-9;
                    border: none;
                    font-weight: 700;
                }
                .update-content:hover {
                    // font-size: 1.4rem;
                    // margin-top: 1rem;
                    // font-family: $font-9;
                    border: none;
                }
            }
        }
    }
}

@media (min-width: 121px) and (max-width: 260px) {
    .template-page2 {
        .banner-image {
            .heading-container {
                bottom: 50%;
                left: -15rem;
                padding: 1.5rem 4rem;
                padding-left: 20rem;
                .page-subheading {
                    font-size: 1.4rem;
                    display: none;
                }
                .page-heading {
                    font-size: 3rem;
                    display: none;
                }
            }
        }
        .left_img {
            display: none;
        }
        img {
            height: 35vh !important;
            object-fit: cover;
            width: 100%;
        }
        .main-content-container {
            background-color: white;
            width: 80%;
            margin: auto;
            .main-content {
                .page-path {
                    font-size: 1.2rem;
                    margin-top: 1.3rem;
                    padding-top: 1.3rem;
                }
                h1 {
                    font-size: 12rem;
                    padding: 0;
                    margin: 0;
                    margin-left: 15rem;
                }

                h2 {
                    font-size: 2rem;
                    font-weight: 600;
                }
                p {
                    font-size: 1rem;
                }
            }
        }
    }
}

@media (min-width: 260.98px) and (max-width: 400px) {
    .template-page2 {
        .banner-image {
            .heading-container {
                bottom: 30%;
                left: -15rem;
                padding: 1.5rem 4rem;
                padding-left: 20rem;
                .page-subheading {
                    font-size: 1.4rem;
                    color: #000000;
                    display: none;
                }
                .page-heading {
                    font-size: 3rem;
                    color: #000000;
                    display: none;
                }
            }
        }
        .left_img {
            display: none;
        }
        img {
            height: 40vh !important;
            object-fit: cover;
            width: 100%;
        }
        .main-content-container {
            background-color: white;
            width: 80%;
            margin: auto;
            .main-content {
                .page-path {
                    font-size: 1.2rem;
                    margin: 1.3rem;
                }
                h1 {
                    font-size: 12rem;
                    padding: 0;
                    margin: 0;
                    margin-left: 15rem;
                }

                h2 {
                    font-size: 2rem;
                    font-weight: 600;
                }
                p {
                    font-size: 1.2rem;
                }
            }
        }
    }
}

@media (min-width: 400.98px) and (max-width: 550.98px) {
    .template-page2 {
        .banner-image {
            .heading-container {
                bottom: 40%;
                left: -16rem;
                padding: 1.5rem 4rem;
                padding-left: 20rem;
                .page-subheading {
                    font-size: 1.3rem;
                }
                .page-heading {
                    font-size: 2.2rem;
                }
            }
        }
        .left_img {
            // display: none;
            background: $about_page_color;
            height: auto;
        }
        img {
            height: 35vh !important;
            object-fit: cover;
            width: 50%;
        }
        .main-content-container {
            background-color: white;
            width: 80%;
            margin: auto;
            .main-content {
                .page-path {
                    font-size: 1.2rem;
                    margin: 1.3rem;
                }
                h1 {
                    font-size: 12rem;
                    padding: 0;
                    margin: 0;
                    margin-left: 15rem;
                }

                h2 {
                    font-size: 1.5rem;
                    font-weight: 600;
                }
                p {
                    font-size: 1.2rem;
                }
            }
        }
    }
}

@media (min-width: 550.98px) and (max-width: 767.98px) {
    .template-page2 {
        .banner-image {
            .heading-container {
                bottom: 40%;
                left: -14rem;
                padding: 1.5rem 4rem;
                padding-left: 20rem;
                .page-subheading {
                    font-size: 1.6rem;
                }
                .page-heading {
                    font-size: 2.6rem;
                }
            }
        }
        .left_img {
            // display: none;
            background: $about_page_color;
            height: auto;
        }
        img {
            height: 40vh !important;
            object-fit: cover;
            width: 50%;
        }
        .main-content-container {
            background-color: white;
            width: 80%;
            margin: auto;
            .main-content {
                .page-path {
                    font-size: 1.2rem;
                    margin: 1.3rem;
                }
                h1 {
                    font-size: 12rem;
                    padding: 0;
                    margin: 0;
                    margin-left: 15rem;
                }

                h2 {
                    font-size: 1.5rem;
                    font-weight: 600;
                }
                p {
                    font-size: 1.35rem;
                }
            }
        }
    }
}

@media (min-width: 767.98px) and (max-width: 991.98px) {
    .template-page2 {
        .banner-image {
            .heading-container {
                bottom: 40%;
                left: -11.5rem;
                padding: 1.5rem 4rem;
                padding-left: 20rem;
                .page-subheading {
                    font-size: 1.9rem;
                }
                .page-heading {
                    font-size: 3rem;
                }
            }
        }
        .left_img {
            // display: none;
            background: $about_page_color;
            height: auto;
        }
        img {
            height: 50vh !important;
            object-fit: cover;
            width: 50%;
        }
        .main-content-container {
            background-color: white;
            width: 80%;
            margin: auto;
            .main-content {
                .page-path {
                    font-size: 1.2rem;
                    margin: 1.3rem;
                }
                h1 {
                    font-size: 12rem;
                    padding: 0;
                    margin: 0;
                    margin-left: 15rem;
                }

                h2 {
                    font-size: 1.5rem;
                    font-weight: 600;
                }
                p {
                    font-size: 1.35rem;
                }
            }
        }
    }
}

@media (min-width: 991.98px) and (max-width: 1024px) {
    .template-page2 {
        .banner-image {
            .heading-container {
                bottom: 40%;
                left: -10rem;
                padding: 1.5rem 4rem;
                padding-left: 20rem;
                .page-subheading {
                    font-size: 2.7rem;
                }
                .page-heading {
                    font-size: 3.9rem;
                }
            }
        }
        .left_img {
            // display: none;
            background: $about_page_color;
        }
        img {
            // height: 70vh !important;
            object-fit: cover;
            width: 50%;
        }
        .main-content-container {
            background-color: white;
            width: 80%;
            margin: auto;
            .main-content {
                .page-path {
                    font-size: 1.2rem;
                    margin: 1.3rem;
                }
                h1 {
                    font-size: 12rem;
                    padding: 0;
                    margin: 0;
                    margin-left: 15rem;
                }

                h2 {
                    font-size: 1.8rem;
                    font-weight: 600;
                }
                p {
                    font-size: 1.45rem;
                }
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1202px) {
    .template-page2 {
        .banner-image {
            .heading-container {
                bottom: 40%;
                left: -10rem;
                padding: 1.5rem 4rem;
                padding-left: 20rem;
                .page-subheading {
                    font-size: 2.7rem;
                }
                .page-heading {
                    font-size: 3.9rem;
                }
            }
        }
        .left_img {
            // display: none;
            background: $about_page_color;
        }
        img {
            // height: 72vh !important;
            object-fit: cover;
            width: 50%;
        }
        .main-content-container {
            background-color: white;
            width: 80%;
            margin: auto;
            .main-content {
                .page-path {
                    font-size: 1.5rem;
                    margin: 1.3rem;
                }
                h1 {
                    font-size: 12rem;
                    padding: 0;
                    margin: 0;
                    margin-left: 15rem;
                }

                h2 {
                    font-size: 2.3rem;
                    font-weight: 600;
                }
                p {
                    font-size: 1.55rem;
                }
            }
        }
    }
}

.template-page {
    min-height: 100vh;
    position: relative;
    width: 100%;
    // background-color: red;
    padding-top: 0rem;

    img {
        object-fit: cover;
        width: 100%;
        height: 60rem;
        z-index: -1;
    }

    .main-content-container {
        background-color: white;
        width: 100%;
        margin: auto;
        // box-shadow: -2px 2px 10px rgba(black,0.5);
        // transform: translateY(-10rem);

        .main-content {
            h1 {
                font-size: 14.4rem;
                font-family: $font-6;
                font-weight: 800;
                padding: 0;
                margin: 0;
                transform: translateY(-90%);
                margin-left: 15rem;
                color: $color-7;
                text-transform: uppercase;
            }

            h2 {
                font-family: $font-6;
                font-size: 4.8rem;
                font-weight: 700;
            }
            p {
                font-size: 2.8rem;
                font-family: $font-6;
                margin-bottom: 4rem;
            }

            .latest-carousel {
                background: #fefefe;
                width: 100%;
                height: 20rem;

                box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
                padding: 1rem;

                p {
                    font-size: 2rem;
                }
            }
        }
    }
}