.head {
    background-color: $navbar-color;
    // position: absolute;
}
.hamBurger {
    background: rgba(#ffffff, 1);
    color: rgba(black, 0.9);
    list-style: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 400px;
    height: 100vh;
    z-index: 201;
    border-right: 1px solid rgb(226, 226, 226);
    overflow-x: hidden ;
    transition: all 0.3s;  
    .hamburger_story { 
        background-color: rgba(rgb(5, 11, 15), 0.6);
        border-radius: 4px;
        h5 {
            font-size: 15px;
        }
        p {
            font-size: 12px;
        }
    }
    .close_hamBurger {
        font-size: 20px;
        padding-left: 2rem;
        margin-bottom: 1.4rem;
        &:hover {
            cursor: pointer;
        }
    }
    a {
        h2 {
            // &:hover {
            //     color: rgba(white, 0.7);
            // }
        }
    }
    hr {
        background-color: rgba(black, 0.3);
    }
    .hamBurger_nav_links {
        overflow: hidden;
        a {
            color: rgba(black, 0.9);
        }
        .fa-arrow-right {
            color: rgba(black, 0.5);
            border-radius: 50%;
            border: 2px solid rgba(black, 0.4);
            width: 30px;
            height: 30px;
        }
        ul {
            padding-left: 0;
            overflow: hidden;
            margin-top: 1rem;
            list-style-type: none;
            li {
                color: rgba(black, 0.8);
                font-family: $font-3;
                font-size: 13px;
                padding: 0.25rem;
            }
        }
        padding: 1.7rem;

        &:hover {
            background: rgba(187, 187, 187, 0.2);
        }
    }
}
.menu-button-container{
    // position: absolute;
    .navbar-menu-button{
        font-family: Open Sans;
        font-weight: 100;
        color: black;
        font-size: 3rem;
        text-transform: lowercase;
        // padding-left: 3rem;
        // background-color: red;

        &:hover {
            cursor: pointer;
        }
    }

    .navbar-menu-button::after{
        content:'';
        font-family: Open Sans;
        font-weight: 100;
        color: black;
        font-size: 3rem;
        text-transform: lowercase;
        padding-left: 3rem;
        // background-color: red;
    }
}
.sideBar {
    background-color: rgba(#ffffff, 1) !important;
    // color: rgba(white, 0.9);
    list-style: none;
    position: absolute;
    top: 0;
    // right: 0;
    // width: 0;
    height: 100%;
    z-index: 201;
    overflow: hidden ;
    transition: all 0.3s;
    border-right: 1px solid rgb(177, 177, 177);
    hr {
        background-color: rgba(black, 0.3);
    }
    .sidebar_nav_links {
        overflow: hidden;
        a {
            color: rgba(black, 0.9);
        }

        h2 {
            color: black;
        }
        ul {
            padding-left: 0;
            overflow: hidden;
            margin-top: 1rem;
            list-style-type: none;
            li {
                color: rgba(black, 0.8);
                font-family: $font-3;
                font-size: 13px;
                padding: 0.25rem;
            }
        }
        margin: 2rem;
    }
    .sidebar_button_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button {
            margin: 1rem;
            padding: 1rem;
            width: 74%;
            border: none;
            outline: none;
            border-radius: 0.3rem;
            border: 1px solid rgb(39, 39, 39);
            transition: all 0.3s;
            // box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
            &:focus {
                transform: scale(0.98);
            }

            h6 {
                margin-bottom: 0;
            }
        }
    }
    .sideBar-item {
        padding: 2.5rem;
    }
    .close_sideBar {
        font-size: 20px;
        .fa-times {
            position: absolute;
            right: 0;
            padding-right: 1.5rem;
            color: rgba(black, 0.9);
        }
        &:hover {
            cursor: pointer;
        }
    }
    .news_in_sidebar {
        padding: 2.5rem;
        ul {
            list-style: none;
            li {
                color: rgba(black, 0.8);
                font-family: $font-9;
                font-size: 14px;
            }
        }
    }
}
.nav-link {
    position: relative;
    &::before { 
        content: "";
        position: absolute; 
        width: 100%;
        height: 2px; 
        bottom: 6px;
        left: 0;
        background-color: #000;
        visibility: hidden;
        transform: translateY(3px);
        transition: all 0.2s ease-in-out;
    }
    &:hover::before {
        transform: translateY(0px);
        // visibility: visible;
        height: 2px; 
    }
}
.rsk_mainPage_Heading {
    font-size: 4.4rem;
    // background-color: red;
    text-align: center;
    // color: rgba(#fefefe, 0.9);
    font-weight: 700;
    padding:0 0 0 1.2rem;
    vertical-align: middle;
    font-family: $font-6;
}
nav {
    // opacity: 0.7;
    border-bottom: 1px solid rgb(221, 221, 221);
    height: 8vh;
    background-color: $navbar-color; 
    a {
        // position: relative;
        font-size: 2rem;
        text-decoration: none;
        color: $color-1;
        // opacity: 0.9;
        font-family: $font-2; //font-2 currently is Source Sans Pro. Check the variables sass file.
        font-style: normal;
        font-weight: normal;
        line-height: 3rem;
        &:hover {
            color: $color-1;
            opacity: 1;
        }
        
    }
    ul {
        li {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }



    //hamBurger open burger icon
    .hamBurger-Open-Icon {
        &:hover {
            cursor: pointer;
        }
    }
}
.icon-bar {
    width: 3rem;
    height: 0.1rem;
    margin: 0.6rem;
    background-color: #000000;
    display: block;
    transition: all 0.2s;
    margin-top: 4px;
    border-radius: 0.6rem;
}
.search-icon {
    margin-top: -3.8rem;
}
.navbar-toggler {
    border: none;
    background: transparent !important;
  }
  
  button.navbar-toggler:focus, .navbar-toggler:active, .navbar-toggler-icon:focus{
    outline: none !important;
    border:none;
    box-shadow:none;
}

.scroll {
    color: #000;
}
.navbar-logo{
    font-family: $font-1;
    color: $color-font-heading;
    font-size: 5rem;
    font-weight: 400;
    line-height: 9.2rem;
}

.nav-item{
    padding: 0 1rem;
}
.iitklogo {
    width: 13rem;
    color: #000000;
}

.rsk-logo {
    width: 50px;
}

@media (max-width:320px) {
    .rsk_mainPage_Heading {
        font-size: 36px;
    }
}

@media (max-width:768px) {
    .navbar_brand_rsk {
        img {
            height: 4.4rem;
        }
    }
    
}

@media (max-width:767.98px) {
    .navbar-menu-button {
        display: none;
    }
    .rsk-logo {
        width: 44px;
    }
}

@media (min-width:768px) {
    .navbar_brand_rsk {
        width: 100%;
        img {
            height: 4.8rem;
        }
    }
    
}

@media (min-width:991px) {
    .navbar_brand_rsk {
        width: 100%;
        img {
            height: 5rem;
        }
    }
}